import * as Yup from 'yup';
import { countries } from '../../data/countries';
import { states } from '../../data/states';
import { User } from './User';
import Card from '../common/Card';
import React, { useState } from 'react';
import { FormField, FormWrapper } from '../common/Form';
import Button from '../common/Button';
import { useFormik } from 'formik';
import ProfilePhoto from './ProfilePhoto';
import { Link } from 'gatsby';
import { useQuery } from 'react-query';
import { format } from 'date-fns';

interface ProfileEditProps {
  user: User;
  setIsEditing: any;
  profilePhoto: any;
  onSubmit: any;
  formState: PROFILE_EDIT_FORM;
}

export enum PROFILE_EDIT_FORM {
  IDLE,
  SUBMITTING,
  ERROR,
  COMPLETE
}

const ProfileEditSchema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  address1: Yup.string()
    .required('Required')
    .nullable(),
  address2: Yup.string().nullable(),
  city: Yup.string()
    .required('Required')
    .nullable(),
  stateProvince: Yup.string()
    .required('Required')
    .nullable(),
  zipPostal: Yup.string()
    .max(10)
    .required('Required')
    .nullable(),
  phone: Yup.string()
    .max(20)
    .required('Required')
    .nullable(),
  country: Yup.string().required('Required'),
  dateOfBirth: Yup.string().nullable(),
  last4SSN: Yup.string()
    .min(4)
    .max(4)
    .nullable(),
  background: Yup.string()
    .max(1000)
    .nullable()
});

export type ProfileEditForm = {
  firstName: string;
  lastName: string;
  address1: string;
  address2?: string;
  city: string;
  stateProvince: string;
  zipPostal?: string;
  country: string;
  phone: string;
  dateOfBirth: string;
  last4SSN: number | null;
  background: string;
};

type IForm = ProfileEditForm;

const ProfileEdit = (props: ProfileEditProps) => {
  const formik = useFormik<IForm>({
    initialValues: {
      firstName: props.user.firstName || '',
      lastName: props.user.lastName || '',
      address1: props.user.address1 || '',
      address2: props.user.address2 || '',
      city: props.user.city || '',
      stateProvince: props.user.stateProvince || '',
      zipPostal: props.user.zipPostal || '',
      country: props.user.country || '',
      phone: props.user.phone || '',
      dateOfBirth: props.user.dateOfBirth
        ? format(new Date(props.user.dateOfBirth), 'MM/dd/yyyy')
        : '',
      last4SSN: props.user.last4SSN || null,
      background: props.user.background || ''
    },
    validationSchema: ProfileEditSchema,
    onSubmit: values => {
      props.onSubmit({
        ...values,
        ...(!!values.last4SSN && { last4SSN: Number(values.last4SSN) })
      });
    }
  });

  return (
    <Card>
      <FormWrapper formik={formik} hideActionButtons={true}>
        <div className="flex border-b border-gray-200 pb-8">
          <div className="w-24">
            <ProfilePhoto photo={props.profilePhoto} />
          </div>
          <div className="flex ml-4 mb-4">
            <div className="w-1/2 mr-2">
              <FormField<IForm> name="firstName" type="text" />
            </div>
            <div className="w-1/2 ml-2">
              <FormField<IForm> name="lastName" type="text" />
            </div>
          </div>
        </div>
        <div className="mt-8 flex">
          <Link
            to="/auth/reset-password"
            className="sans-serif text-primary hover:underline hover:text-primary-dark"
          >
            Reset Password &rarr;
          </Link>
        </div>
        <div className="mt-8 flex">
          <div className="w-1/2 mr-8">
            <div className="mb-8">
              <div className="mb-4">
                <FormField<IForm> label="Address" name="address1" type="text" />
              </div>
              <div className="mb-4">
                <FormField<IForm>
                  label="Apt / Suite"
                  placeholder="Apt / Suite"
                  name="address2"
                  type="text"
                />
              </div>
              <div className="flex mb-4">
                <div className="w-2/3 mr-1">
                  <FormField<IForm> name="city" type="text" />
                </div>
                <div className="w-1/3 ml-1">
                  {formik.values.country !== 'United States' ? (
                    <FormField<IForm>
                      name="stateProvince"
                      type="text"
                      label="State"
                      placeholder="State"
                    />
                  ) : (
                    <FormField<IForm>
                      name="stateProvince"
                      label="State"
                      placeholder="State"
                      type="select"
                      options={states.map(s => ({
                        label: s.name,
                        value: s.value
                      }))}
                    />
                  )}
                </div>
              </div>
              <div className="flex">
                <div className="w-1/2 mr-1">
                  <FormField<IForm>
                    name="zipPostal"
                    type="text"
                    label="Zip / Postal Code"
                    placeholder="Zip or Postal"
                  />
                </div>
                <div className="w-1/2 ml-1">
                  <FormField<IForm>
                    name="country"
                    type="select"
                    onChange={v => {
                      formik.setFieldValue('stateProvince', '', true);
                    }}
                    options={countries.map(c => ({
                      label: c.name,
                      value: c.name
                    }))}
                  />
                </div>
              </div>
            </div>
            <div className="mb-4">
              <FormField<IForm>
                name="phone"
                placeholder="(555) 555-5555"
                type="mask"
                mask="phoneNumber"
              />
            </div>
            <div className="mb-8">
              <p className="text-sm text-gray-700 sans-serif">
                If you are a teacher in New York state and wish to receive CTLE
                (Continuing Teacher and Leadership Education) credit for any
                events please provide your DOB and the last four numbers of your
                SSN.
              </p>
              <div className="mt-4">
                <div className="mb-4">
                  <FormField<IForm>
                    name="dateOfBirth"
                    placeholder="MM/DD/YYYY"
                    type="mask"
                    mask="dateOfBirth"
                  />
                </div>
                <div className="mb-4">
                  <FormField<IForm>
                    name="last4SSN"
                    label="Last 4 of SSN"
                    placeholder="Last 4 of SSN"
                    type="mask"
                    mask="last4SSN"
                  />
                </div>
                <FormField<IForm>
                  name="background"
                  placeholder="Tell us about yourself"
                  type="textarea"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 flex">
          <Button
            text="Save Profile"
            type="submit"
            isLoading={props.formState === PROFILE_EDIT_FORM.SUBMITTING}
          />
          <button
            className="ml-4 text-red-500 sans-serif my-auto cursor-pointer"
            onClick={props.setIsEditing}
          >
            Close
          </button>
        </div>
      </FormWrapper>
    </Card>
  );
};

export default ProfileEdit;
