import { toast } from 'react-toastify';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Card from '../../common/Card';
import LoadingDialog from '../../common/LoadingDialog';
import { getEventApplication, updateEventApplication } from '../api';
import ApplicationSaveControls from '../ApplicationSaveControls';
import EventPathGroupingsRadio from '../EventPathGroupingsRadio';
import EventSectionsList from './EventSectionsList';
import EventApplicationHeader from './EventApplicationHeader';
import { EventApplication } from '../NewApiTypes.generated';
import {
  getHistoricEvents,
  HistoricEventType
} from '../../../helpers/previousAttendance.helper';
import { differenceInYears } from 'date-fns';
import { EventManagementContext } from '../EventManagement';

interface EventApplicationSectionsProps {
  path: string;
  children: React.ReactChildren;
  applicationId: string;
}

export enum SectionTypeEnum {
  FIRST_YEAR = 'First Year',
  ADVANCED = 'Advanced'
}

interface IForm {
  requestedSections: { id: string; ranking: number; groupingId?: string }[];
  requestedGroupings: string[];
}

const appliedCoachingCheck = (
  events: HistoricEventType[],
  eventSubType: string
): boolean => {
  const coachingSubTypes = ['Coaching Reading', 'Coaching Writing'];
  return (
    coachingSubTypes.includes(eventSubType) &&
    events.some(e => coachingSubTypes.includes(e.eventSubType as string))
  );
};

export const getPathType = (application: EventApplication): SectionTypeEnum => {
  const eventHistory = application?.user?.eventHistory;
  const hasAdvancedPath = application?.event?.paths?.some(
    (p: any) => p.name === SectionTypeEnum.ADVANCED
  );
  if (!eventHistory || !hasAdvancedPath) {
    return SectionTypeEnum.FIRST_YEAR;
  }

  const TEN_YEARS = 10;
  const events = getHistoricEvents(eventHistory).filter(history =>
    history.dates?.some(
      date => differenceInYears(new Date(), new Date(date)) < TEN_YEARS
    )
  );

  if (
    events.some(
      ev =>
        ev.eventType === application?.event?.eventType?.name &&
        ev.eventSubType === application?.event?.eventSubType?.name
    )
  ) {
    return SectionTypeEnum.ADVANCED;
  }

  if (
    appliedCoachingCheck(
      events,
      application?.event?.eventSubType?.name as string
    )
  ) {
    return SectionTypeEnum.ADVANCED;
  }

  return SectionTypeEnum.FIRST_YEAR;
};

const EventApplicationSections = (props: EventApplicationSectionsProps) => {
  const { setIsSomeSectionSelected } = useContext(EventManagementContext);

  const getPath = () =>
    pathType === SectionTypeEnum.FIRST_YEAR ? firstYearPath : advancedPath;

  const [pathType, setPathType] = useState<SectionTypeEnum | null>(null);
  const [firstYearPath, setFirstYearPath] = useState<any>(null);
  const [advancedPath, setAdvancedPath] = useState<any>(null);
  const [requireRankedSelection, setRequireRankedSelection] = useState<boolean>(
    false
  );
  const [rankedSelectionAmount, setRankedSelectionAmount] = useState<boolean>(
    false
  );
  const [form, setForm] = useState<IForm>({
    requestedSections: [],
    requestedGroupings: []
  });
  const [formValid, setFormValid] = useState<boolean>(false);

  useEffect(() => {
    if (form) {
      const path = getPath();
      if (path) {
        let valid = true;
        const { requireRankedSelection, rankedSelectionAmount } = path;
        setRequireRankedSelection(requireRankedSelection);
        setRankedSelectionAmount(rankedSelectionAmount);
        if (requireRankedSelection) {
          if (
            rankedSelectionAmount > 0 &&
            form.requestedSections.length !==
              rankedSelectionAmount * path.groupings?.length
          ) {
            valid = false;
          }
        } else {
          valid =
            path.applicationSelectionLevel === 'GROUPINGS'
              ? form.requestedGroupings.length === 1
              : form.requestedSections.length ===
                Math.max(1, path?.groupings?.length || 0);
        }
        setFormValid(valid);
      }
    }
  }, [form, pathType]);

  const { data, isLoading, error } = useQuery(
    ['eventApplication'],
    () => getEventApplication(props.applicationId),
    {
      onSuccess: data => {
        const pathType = getPathType(data?.application);
        setPathType(pathType);
        setFirstYearPath(
          data.application.event.paths.find(
            (p: any) => p.name === SectionTypeEnum.FIRST_YEAR
          )
        );
        setAdvancedPath(
          data.application.event.paths.find(
            (p: any) => p.name === SectionTypeEnum.ADVANCED
          )
        );
        const requestedGroupings = data?.application?.requestedGroupings.map(
          (rg: any) => rg.eventPathGroupingId
        );
        const potentiallyRequestedSections = data?.application?.requestedSections?.map(
          (rs: any) => ({
            id: rs.eventSectionId,
            ranking: rs.ranking,
            groupingId: rs.eventSections?.eventPathGroupingId
          })
        );
        const requestedSections: any[] = [];
        potentiallyRequestedSections.forEach((prs: any) => {
          if (
            !prs.groupingId ||
            !requestedSections.some(rs => rs.groupingId === prs.groupingId)
          ) {
            requestedSections.push(prs);
          }
        });
        const isSomeSectionSelected =
          pathType === SectionTypeEnum.ADVANCED
            ? requestedSections.length > 0
            : requestedGroupings.length > 0;

        setIsSomeSectionSelected(isSomeSectionSelected);
        setForm({
          requestedGroupings,
          requestedSections
        });
      }
    }
  );

  const updateSelectedGroupings = (value: string) => {
    setForm({ ...form, requestedGroupings: [value] });
  };

  const updateSelectedSection = (value: {
    sectionId: string;
    groupingId: string;
  }) => {
    const currentRequestedSections = form.requestedSections;
    const existingSectionIdx = currentRequestedSections.findIndex(
      rs => rs.groupingId === value.groupingId
    );
    if (existingSectionIdx > -1) {
      currentRequestedSections[existingSectionIdx] = {
        id: value.sectionId,
        ranking: 1,
        groupingId: value.groupingId
      };
    } else {
      currentRequestedSections.push({
        id: value.sectionId,
        ranking: 1,
        groupingId: value.groupingId
      });
    }
    setForm({
      ...form,
      requestedSections: currentRequestedSections
    });
  };

  const updateSelectedSections = (
    values: { id: string; ranking: number }[]
  ) => {
    setForm({ ...form, requestedSections: values });
  };

  const mutation = useMutation(['saveSections'], updateEventApplication);

  const save = async (shouldNavigate = false) => {
    try {
      await mutation.mutate(
        {
          segment: 'SECTIONS',
          applicationId: props.applicationId,
          data: {
            ...form,
            requestedSections: form.requestedSections.map(rs => ({
              id: rs.id,
              ranking: rs.ranking
            }))
          }
        },
        {
          onSuccess: data => {
            if (data.state === 'BAD_REQUEST') {
              toast.error(data.message || 'Something went wrong');
            } else {
              setIsSomeSectionSelected(true);
              toast.success('Your application has been saved');
              if (shouldNavigate) {
                navigate(`../questionnaire`);
              }
            }
          }
        }
      );
    } catch (err) {
      toast.error('There was an issue during application update');
    }
  };

  const renderSelectionComponent = () => {
    const path = getPath();
    return (
      <>
        {path.applicationSelectionLevel === 'GROUPINGS' && (
          <EventPathGroupingsRadio
            applicationSelectionLevel={path.applicationSelectionLevel}
            entries={path?.groupings}
            selectedEntries={form.requestedGroupings[0]}
            setSelected={updateSelectedGroupings}
          />
        )}
        {/* if there is no required ranking we need to select single section for advanced */}
        {path.applicationSelectionLevel === 'SECTIONS' &&
          !requireRankedSelection && (
            <EventPathGroupingsRadio
              applicationSelectionLevel={path.applicationSelectionLevel}
              entries={path?.groupings}
              selectedEntries={form.requestedSections.map(rs => ({
                sectionId: rs.id,
                groupingId: rs.groupingId as string
              }))}
              setSelected={updateSelectedSection}
            />
          )}
        {/* if there is required ranking we need to select multiple sections for advanced with ranking */}
        {path.applicationSelectionLevel === 'SECTIONS' &&
          requireRankedSelection && (
            <EventSectionsList
              path={path}
              selectedSections={form.requestedSections}
              setSelected={updateSelectedSections}
            />
          )}
      </>
    );
  };

  return (
    <>
      <Card>
        <EventApplicationHeader
          title="Sections"
          description={
            pathType === SectionTypeEnum.FIRST_YEAR || !requireRankedSelection
              ? `Please select the section you would like to apply for`
              : `Please select top ${rankedSelectionAmount} sections you would like to apply for`
          }
        />
        {isLoading ? <LoadingDialog /> : null}
        {firstYearPath || advancedPath ? (
          <section>
            <div className="athy-5 my-4">
              <h2 className="text-primary mb-2 font-bold text-lg">
                {pathType === SectionTypeEnum.FIRST_YEAR
                  ? firstYearPath?.name
                  : advancedPath?.name}
              </h2>
              {renderSelectionComponent()}
            </div>
            <ApplicationSaveControls
              isDisabled={!formValid}
              saveApplication={() => save()}
              saveApplicationAndContinue={() => save(true)}
            />
          </section>
        ) : null}
      </Card>
    </>
  );
};

export default EventApplicationSections;
