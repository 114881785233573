import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
import React, { Fragment, useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { formatAsCurrency } from '../../util';
import Button from '../common/Button';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import {
  getPackageRequestById,
  getPackageRequestTotals,
  updatePackageRequest
} from './api';
import PackageCostTotals, {
  getGroupTotals,
  getInstitutesTotal,
  getPackageCosts,
  getPackageRequestGroupSectionTotal,
  getPDSessionCostRange,
  getTotalEventCost,
  getTravelCost,
  PackageCosts,
  PackageRequestTotals,
  prepareCurrencyCalculation
} from './PackageCostTotals';
import PackageSubmitConfirmation from './PackageSubmitConfirmation';
import {
  Maybe,
  Package,
  PackageRequest,
  PackageRequestEvent,
  PackageRequestGroup,
  PackageRequestGroupSection,
  PackageRequestInstitute,
  PackageRequestSchoolBasedPDSession
} from './NewApiTypes.generated';
import { getNextRoute, isEditablePackageRequest } from './PackageSaveControls';
import { FETCH_STATES, useFetch } from '../../hooks/useFetch';
import moment from 'moment';
import { getPackageGroupsGroupsMinimumAmounts } from './GroupSelection';
import { navigate } from 'gatsby';
import { PackageContext } from '../../context/PackageContext';
import { getPDSessionTotalByGrouping } from './StaffOptions';

const removeZeroValuesFromPackageRequest = (
  packageRequest: PackageRequest
): PackageRequest => {
  return {
    ...packageRequest,
    requestedPDSessions: packageRequest.requestedPDSessions.filter(r =>
      r?.overrideDate ? r.overrideAmount : r?.requestedDays
    ),
    requestedEvents: packageRequest.requestedEvents.filter(e =>
      e?.overrideDate ? e.overrideAmount : e?.requestedParticipants
    ),
    requestedInstitutes: packageRequest.requestedInstitutes.filter(i =>
      i?.overrideDate ? i.overrideAmount : i?.requestedParticipants
    ),
    requestedGroups: packageRequest.requestedGroups
      .filter(g => {
        return g?.overrideDate
          ? g.overrideRequestedSections?.reduce(
              (sum, curr) => (sum += curr.requestedParticipants),
              0
            )
          : g?.requestedSections?.reduce(
              (sum, curr) => (sum += curr?.requestedParticipants || 0),
              0
            );
      })
      .map(
        g =>
          ({
            ...g,
            overrideRequestedSections:
              g?.overrideRequestedSections?.filter(
                s => s?.requestedParticipants
              ) || [],
            requestedSections:
              g?.requestedSections?.filter(s => s?.requestedParticipants) || []
          } as Maybe<PackageRequestGroup>)
      )
  };
};

interface PDSessionRequestSummaryProps {
  packageRequest: PackageRequest;
  packageCosts: PackageCosts;
  pdSessionTotals: { totalCostRange: number[] };
}

const PDSessionRequestSummary = ({
  packageRequest,
  packageCosts,
  pdSessionTotals
}: PDSessionRequestSummaryProps) => {
  const { totalCostRange } = pdSessionTotals;

  return (
    <div className="border border-gray-300 rounded-lg overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200 p-4">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Day Type
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Requested Days
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Session Cost
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Travel Cost
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Total
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {!packageRequest.requestedPDSessions.length ? (
            <tr>
              <td className="py-3 px-6 sans-serif">
                No staff developer options selected
              </td>
            </tr>
          ) : (
            <>
              {packageRequest.requestedPDSessions.map(
                // @ts-ignore
                (s: PackageRequestSchoolBasedPDSession, i: number) => {
                  const packageCosts: PackageCosts = getPackageCosts({
                    ...packageRequest,
                    requestedPDSessions: [s]
                  });

                  const amount =
                    (s.overrideDate ? s.overrideAmount : s.requestedDays) || 0;
                  const pdSessionCostsItem = getPDSessionCostRange(
                    [s],
                    packageCosts
                  );

                  const {
                    totalCostRange: totalCostRangeItem,
                    sessionCosts: sessionCostsItem,
                    travelCosts: travelCostsItem
                  } = pdSessionCostsItem;
                  return (
                    <tr key={i}>
                      <td className="py-3 px-6 sans-serif">
                        {s.packageSchoolBasedPDSession.pdSession.name}
                      </td>
                      <td className="py-3 px-6 sans-serif">{amount}</td>
                      {packageCosts ? (
                        <>
                          <td className="py-3 px-6 sans-serif">
                            {formatAsCurrency(sessionCostsItem[0])}
                            {' - '}
                            {formatAsCurrency(sessionCostsItem[1])}
                          </td>
                          <td className="py-3 px-6 sans-serif">
                            {formatAsCurrency(travelCostsItem)}
                          </td>
                          <td className="py-3 px-6 sans-serif">
                            {formatAsCurrency(totalCostRangeItem[0])}
                            {' - '}
                            {formatAsCurrency(totalCostRangeItem[1])}
                          </td>
                        </>
                      ) : null}
                    </tr>
                  );
                }
              )}
              <tr className="bg-gray-100">
                <td className="py-3 px-6 font-bold sans-serif">
                  Initial Price Range
                </td>
                <td></td>
                <td></td>
                <td></td>
                {packageCosts ? (
                  <td className="py-3 px-6 font-bold sans-serif">
                    {formatAsCurrency(totalCostRange[0])} -{' '}
                    {formatAsCurrency(totalCostRange[1])}
                  </td>
                ) : null}
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

interface EventRequestSummaryProps {
  packageRequest: PackageRequest;
}

const EventRequestSummary = ({ packageRequest }: EventRequestSummaryProps) => {
  return (
    <div className="border border-gray-300 rounded-lg overflow-hidden">
      <table className="min-w-full divide-y divide-gray-200 p-4">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Event
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Price
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Seats
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Cost
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {!packageRequest.requestedEvents.length ? (
            <tr>
              <td className="py-3 px-6 w-2/3 sans-serif">No events selected</td>
            </tr>
          ) : (
            <>
              {packageRequest.requestedEvents.map((e, i: number) => (
                <tr key={i}>
                  <td className="py-3 px-6 w-2/3 sans-serif">
                    <p className="font-bold">{e?.packageEvent?.event?.name}</p>
                  </td>
                  <td className="py-3 px-6 sans-serif">
                    {formatAsCurrency(
                      prepareCurrencyCalculation(
                        e?.packageEvent?.ticketType?.price || 0
                      )
                    )}
                  </td>
                  <td className="py-3 px-6 sans-serif">
                    {(e?.overrideDate
                      ? e?.overrideAmount
                      : e?.requestedParticipants) || 0}
                  </td>
                  <td className="py-3 px-6 sans-serif">
                    {formatAsCurrency(
                      prepareCurrencyCalculation(
                        (e?.packageEvent?.ticketType?.price || 0) *
                          ((e?.overrideDate
                            ? e?.overrideAmount
                            : e?.requestedParticipants) || 0)
                      )
                    )}
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

interface GroupsSummaryProps {
  packageRequest: PackageRequest;
}
const GroupsSummary = ({ packageRequest }: GroupsSummaryProps) => {
  return (
    <div className="border border-gray-300 rounded-lg overflow-hidden mt-4">
      <table className="min-w-full divide-y divide-gray-200 p-4">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Group
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Price
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Seats
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Cost
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {!packageRequest.requestedGroups.length ? (
            <tr>
              <td className="py-3 px-6 w-2/3 sans-serif">
                No staff developer led groups selected
              </td>
            </tr>
          ) : (
            <>
              {packageRequest.requestedGroups.map(
                // @ts-ignore
                (g: PackageRequestGroup, i: number) => {
                  const sections = g.overrideDate
                    ? (g.overrideRequestedSections as PackageRequestGroupSection[])
                    : g.requestedSections;
                  return (
                    <Fragment key={i}>
                      <tr>
                        <td className="py-3 px-6 w-2/3 sans-serif">
                          <p className="font-bold mb-2 sans-serif">
                            {g.packageGroup.group.name}
                          </p>
                        </td>
                        <td className="py-3 px-6 sans-serif"></td>
                        <td className="py-3 px-6 sans-serif">
                          {sections.reduce(
                            (sum, curr) =>
                              (sum += curr?.requestedParticipants || 0),
                            0
                          )}
                        </td>
                        <td className="py-3 px-6 sans-serif"></td>
                      </tr>
                      {sections.map(
                        // @ts-ignore
                        (s: PackageRequestGroupSection, i: number) => (
                          <tr key={i}>
                            <td className="text-sm text-gray-700 py-2 px-6 sans-serif">
                              {s.packageGroupSection.groupSection.title}
                            </td>
                            <td className="py-1 px-6 sans-serif">
                              {formatAsCurrency(
                                prepareCurrencyCalculation(
                                  g.packageGroup.attendeeCost
                                )
                              )}
                            </td>
                            <td className="py-1 px-6 sans-serif">
                              {s.requestedParticipants}
                            </td>
                            <td className="py-1 px-6 sans-serif">
                              {formatAsCurrency(
                                getPackageRequestGroupSectionTotal([g], s?.id)
                              )}
                            </td>
                          </tr>
                        )
                      )}
                    </Fragment>
                  );
                }
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

interface InstitutesSummaryProps {
  packageRequest: PackageRequest;
}

const InstitutesSummary = ({ packageRequest }: InstitutesSummaryProps) => {
  return (
    <div className="border border-gray-300 rounded-lg overflow-hidden mt-4">
      <table className="min-w-full divide-y divide-gray-200 p-4">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Institute
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Price
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Seats
            </th>
            <th scope="col" className="text-left py-3 px-6 sans-serif">
              Cost
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {!packageRequest.requestedInstitutes.length ? (
            <tr>
              <td className="py-3 px-6 w-2/3 sans-serif">
                No institutes selected
              </td>
            </tr>
          ) : (
            <>
              {packageRequest.requestedInstitutes.map(
                // @ts-ignore
                (institute: PackageRequestInstitute, i: number) => {
                  const amount =
                    (institute?.overrideDate
                      ? institute.overrideAmount
                      : institute.requestedParticipants) || 0;
                  return (
                    <tr key={i}>
                      <td className="text-gray-800 py-2 px-6 w-2/3 sans-serif">
                        <span className="font-semibold">
                          <p className="font-bold sans-serif">
                            {institute.packageInstitute.event.name}
                          </p>
                        </span>
                        <span className="ml-2 sans-serif">
                          Grades:{' '}
                          {institute.requestedParticipantDistribution
                            .map(d => {
                              const participants =
                                (d?.overrideDate
                                  ? d.overrideParticipants
                                  : d?.participants) || 0;
                              const grade = d?.grade?.name || '';

                              return [grade, participants];
                            })
                            .filter(d => d[1])
                            .map(d => d[0] + ' — ' + d[1])
                            .join(', ')}
                        </span>
                      </td>
                      <td className="py-3 px-6 sans-serif">
                        {formatAsCurrency(
                          prepareCurrencyCalculation(
                            institute.packageInstitute.ticketType?.price || 0
                          )
                        )}
                      </td>
                      <td className="py-3 px-6 sans-serif">{amount}</td>
                      <td className="py-3 px-6 sans-serif">
                        {formatAsCurrency(
                          prepareCurrencyCalculation(
                            institute.packageInstitute.ticketType?.price || 0
                          ) * amount
                        )}
                      </td>
                    </tr>
                  );
                }
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

const DownloadReviewButton = ({ review }: { review: any }) => {
  const [downloadReviewLoading, setDownloadReviewLoading] = useState(false);

  return (
    <Button
      text="Download Review"
      icon={faFilePdf}
      isLoading={downloadReviewLoading}
      onClick={async () => {
        try {
          setDownloadReviewLoading(true);
          const req = await fetch(
            `${process.env.GATSBY_PDF_GEN_DOWNLOAD_REVIEW_URL}`,
            {
              method: 'POST',
              body: JSON.stringify(review),
              headers: {
                'Content-Type': 'application/json',
                Api_key: process.env.GATSBY_PDF_GEN_API_KEY as string
              }
            }
          );

          const buffer = await req.arrayBuffer();
          var blob = new Blob([buffer], {
            type: 'application/pdf'
          });

          setDownloadReviewLoading(false);
          saveAs(blob, 'package-review.pdf');
        } catch (err) {
          setDownloadReviewLoading(false);
          toast.error('Something went wrong');
        }
      }}
    />
  );
};

export const shouldDisableSubmitPackageRequest = (
  packageRequest: PackageRequest
) => {
  const packageMinimum = packageRequest.package.minimumAmount || 0;
  const packageCosts = getPackageCosts(packageRequest);
  const pdSessionTotals = getPDSessionCostRange(
    packageRequest.requestedPDSessions as PackageRequestSchoolBasedPDSession[],
    packageCosts
  );

  const eventTotals = getTotalEventCost(
    packageRequest.requestedEvents as PackageRequestEvent[]
  );

  const groupTotals = getGroupTotals(
    packageRequest.requestedGroups as PackageRequestGroup[]
  )?.total;

  const instituteTotals = getInstitutesTotal(
    packageRequest.requestedInstitutes as PackageRequestInstitute[]
  );

  const costRange = {
    low: sumArray([
      pdSessionTotals.totalCostRange[0],
      eventTotals,
      groupTotals,
      instituteTotals
    ]),
    high: sumArray([
      pdSessionTotals.totalCostRange[1],
      eventTotals,
      groupTotals,
      instituteTotals
    ])
  };

  return costRange.low < packageMinimum / 100;
};

const sumArray = (arr: number[]) => {
  return arr.reduce((a, b) => a + b, 0);
};

interface ReviewProps {
  path: string;
  packageRequestId: string;
}

const Review = (props: ReviewProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const packageContext = useContext(PackageContext);
  const [packageRequest, setPackageRequest] = useState<PackageRequest>();

  const [review, setReview] = useState<undefined | any>();
  const [showPackageSubmitted, setShowPackageSubmitted] = useState(false);
  const [usingPtaFunds, setUsingPtaFunds] = useState(false);

  const {
    apply: applyUpdatePackageRequest,
    state: updatePackageRequestState
  } = useFetch(updatePackageRequest);

  const { data, isLoading } = useQuery<{
    packageRequest: PackageRequest;
    packageRequestSummary: any;
  }>(
    'packageRequestQuery',
    () => getPackageRequestById(props.packageRequestId),
    {
      onSuccess(data) {
        if (data.packageRequest) {
          const pr: PackageRequest = removeZeroValuesFromPackageRequest(
            data.packageRequest
          );
          setIsSubmitted(pr.status === 'SUBMITTED');
          setPackageRequest(pr);
        }
      }
    }
  );

  const { data: totalsData, isLoading: isTotalsLoading } = useQuery<{
    pdfData: any;
    totals: PackageRequestTotals;
  }>(
    'packageRequestTotalsQuery',
    () => getPackageRequestTotals(props.packageRequestId, {}),
    {
      onSuccess: data => {
        if (data.pdfData) {
          setReview(data.pdfData);
        }
      }
    }
  );

  const groupRequirePurchase = getPackageGroupsGroupsMinimumAmounts(
    data?.packageRequest as PackageRequest
  );

  const pdSessionsTotalsByGroupings = getPDSessionTotalByGrouping(
    packageRequest as PackageRequest
  );

  return (
    <>
      <SEO title="Application Review" />

      <Card>
        {isLoading ? <LoadingDialog /> : null}
        {packageRequest ? (
          <>
            {isSubmitted && showPackageSubmitted && (
              <div className="p-4 bg-green-600 -mx-4 lg:-mx-8 -mt-4 lg:-mt-8 mb-2 text-white rounded-t-lg shadow">
                Your package application has been submitted
              </div>
            )}
            {isSubmitted && (
              <div className="mt-4 mb-4 w-full flex justify-center">
                <DownloadReviewButton review={review} />
              </div>
            )}
            <h2 className="sans-serif text-primary font-bold text-2xl border-b border-gray-300">
              {packageRequest.organization.name} - {packageRequest.package.name}
            </h2>
            <h3 className="text-2xl text-gray-600 font-bold mt-4 mb-4">
              Review
            </h3>
            <p className="sans-serif">
              Once you click the{' '}
              <span className="font-bold sans-serif">Submit Application</span>{' '}
              button below, you will receive a confirmation email. Please note
              that the cost below is a range due to the varying cost of staff
              developers and senior staff developers. Your total cost will be
              confirmed upon assignment of staff developers. Your application is
              not complete until you click{' '}
              <span className="font-bold sans-serif">Submit Application</span>.
            </p>
            <h3 className="text-2xl text-gray-600 font-bold mt-4 mb-4">
              Overview
            </h3>

            {data &&
            !!packageRequest &&
            !!data.packageRequestSummary &&
            !!totalsData?.totals ? (
              <>
                {packageRequest.requestedPDSessions.length > 0 && (
                  <>
                    <div className="mb-1">
                      <label className="text-gray-600 sans-serif text-lg">
                        School-Based PD Sessions
                      </label>
                    </div>
                    <div className="mb-6">
                      <PDSessionRequestSummary
                        packageRequest={packageRequest}
                        packageCosts={totalsData.totals.packageCosts}
                        pdSessionTotals={totalsData.totals.pdSessionTotals}
                      />
                    </div>
                  </>
                )}
                {packageRequest.requestedEvents.length > 0 && (
                  <>
                    <div className="mb-1">
                      <label className="text-gray-600 sans-serif text-lg">
                        Events
                      </label>
                    </div>
                    <div className="mb-4">
                      <EventRequestSummary packageRequest={packageRequest} />
                    </div>
                  </>
                )}
                {packageRequest.requestedGroups.length > 0 && (
                  <>
                    <div className="mb-1">
                      <label className="text-gray-600 sans-serif text-lg">
                        Staff-Developer Led Groups
                      </label>
                    </div>
                    <div className="mb-4">
                      <GroupsSummary packageRequest={packageRequest} />
                    </div>
                  </>
                )}
                {packageRequest.requestedInstitutes.length > 0 && (
                  <>
                    <div className="mb-1">
                      <label className="text-gray-600 sans-serif text-lg">
                        Institutes
                      </label>
                    </div>
                    <div className="mb-4">
                      <InstitutesSummary packageRequest={packageRequest} />
                    </div>
                  </>
                )}
              </>
            ) : null}

            {!isSubmitted && (
              <div className="mt-4">
                <DownloadReviewButton review={review} />
              </div>
            )}
            <div className="mt-10 flex justify-between">
              <PackageCostTotals
                totals={totalsData?.totals}
                package={packageRequest.package}
                isLoading={isTotalsLoading}
                showRedTotal
              />
              <div>
                {groupRequirePurchase.some(r => r.shouldDisable) && (
                  <div className="text-yellow-600 mx-4 mb-4">
                    There are several groups that require a minimum value that
                    you have not reached:
                    <ul className="list-disc ml-4">
                      {groupRequirePurchase
                        .filter(r => r.shouldDisable)
                        .map(r => (
                          <li key={r.packageGroupId}>
                            {r.name} - {formatAsCurrency(r.amount / 100)}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}

                {pdSessionsTotalsByGroupings.some(
                  g => g.minAmount > g.amount
                ) && (
                  <div className="text-yellow-600 mx-4 mb-4">
                    There are several staff option groups that require a minimum
                    value that you have not reached:
                    <ul className="list-disc ml-4">
                      {pdSessionsTotalsByGroupings
                        .filter(g => g.minAmount > g.amount)
                        .map(g => (
                          <li key={g?.groupingId}>
                            {g.title} - {g.minAmountLabel}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}

                <div className="my-auto ml-8">
                  {/* <div className="mb-2">
                        {checkDeveloperSectionsForCapacity(
                          organizationPackage
                        ) && (
                          <div>
                            <p className="text-red-600 italic">
                              Some specialty group selections for this package are
                              invalid due to capacity limits and are marked in red
                              on your application. Please go back to the Group
                              Selection tab and remove these selections before
                              submitting.
                            </p>
                          </div>
                        )}
                      </div> */}
                  {!isSubmitted && (
                    <div>
                      <input
                        type="checkbox"
                        id="ptaFunds"
                        name="ptaFunds"
                        checked={usingPtaFunds}
                        onChange={e => {
                          if (e.target.checked) {
                            setUsingPtaFunds(true);
                          } else if (!e.target.checked) {
                            setUsingPtaFunds(false);
                          }
                        }}
                      />
                      <label
                        htmlFor="ptaFunds"
                        className="ml-2 sans-serif text-gray-700"
                      >
                        Check this box ONLY if your school will be using PA/PTA
                        funds to pay for services
                      </label>
                    </div>
                  )}

                  <div className="flex justify-center mt-4">
                    {isEditablePackageRequest(packageRequest) && (
                      <button
                        className="text-primary hover:underline text-lg mr-10"
                        onClick={() => {
                          history.back();
                          packageContext.setHasUnsavedChanges(false);
                        }}
                      >
                        &larr; Previous
                      </button>
                    )}
                    {!isSubmitted && (
                      <Button
                        text="Submit Application"
                        disabled={
                          !isEditablePackageRequest(packageRequest) ||
                          shouldDisableSubmitPackageRequest(packageRequest) ||
                          groupRequirePurchase.some(r => r.shouldDisable) ||
                          pdSessionsTotalsByGroupings.some(
                            g => g.minAmount > g.amount
                          )
                        }
                        isLoading={
                          updatePackageRequestState === FETCH_STATES.PROCESSING
                        }
                        onClick={async () => {
                          const res = await applyUpdatePackageRequest({
                            packageRequestId: packageRequest.id,
                            segmentData: {
                              usingPtaFunds,
                              packageRequestId: packageRequest.id
                            },
                            review,
                            segment: 'SUBMIT'
                          });
                          if (res.state === 'SUCCESS') {
                            setShowPackageSubmitted(true);
                            setIsSubmitted(true);
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth'
                            });
                          } else {
                            toast.error(
                              res.data?.message ||
                                res.data?.error ||
                                'Something went wrong'
                            );
                          }
                        }}
                      />
                    )}
                  </div>
                </div>

                {!isSubmitted && (
                  <div className="mt-5 ml-auto mr-2 lg:w-2/3 sans-serif">
                    {shouldDisableSubmitPackageRequest(packageRequest)
                      ? 'Package total below minimum. Please select more options before continuing'
                      : `Before submitting your package application, please review
                    your selections and ensure you have arranged your package as
                    required`}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : null}
      </Card>
    </>
  );
};

export default Review;
