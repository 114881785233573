import React, { ReactNode } from 'react';
import Card from '../common/Card';
import SEO from '../Seo';
import MembersPageTitle from './MembersPageTitle';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBox, faSchool } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';

type PurchaseOrderLinkProps = {
  text: string;
  icon: IconProp;
} & (
  | {
      asButton: true;
      onClick: () => void;
    }
  | {
      asButton?: null | false | undefined;
      link: string;
    }
);

const PurchaseOrderLink = (props: PurchaseOrderLinkProps) => {
  const children = (
    <>
      <span className="text-5xl mx-auto">
        <FontAwesomeIcon icon={props.icon} className="text-white text-center" />
      </span>
      <span className="mt-4 text-2xl sans-serif text-white text-center">
        {props.text}
      </span>
    </>
  );

  return props.asButton ? (
    <button
      className="bg-primary p-8 rounded-xl flex flex-col text-center shadow-lg cursor-pointer"
      type="button"
      onClick={props.onClick}
    >
      {children}
    </button>
  ) : (
    <a
      href={props.link}
      className="bg-primary p-8 rounded-xl flex flex-col text-center shadow-lg"
    >
      {children}
    </a>
  );
};

interface PurchaseOrdersProps {
  path: string;
  children: ReactNode;
}
const PurchaseOrders = (props: PurchaseOrdersProps) => {
  return (
    <div>
      <SEO title="Purchase Orders" />
      <MembersPageTitle title="Purchase Orders" />
      <Card>
        <section className="flex justify-center">
          <div className="mr-2">
            <PurchaseOrderLink
              text="Submit a Purchase Order for an Institute"
              icon={faSchool}
              asButton
              onClick={() => navigate(`/members/purchase-orders/institute`)}
            />
          </div>
          <div className="ml-2">
            <PurchaseOrderLink
              text="Submit a Purchase Order for a Package"
              icon={faBox}
              asButton
              onClick={() => navigate(`/members/purchase-orders/package`)}
            />
          </div>
        </section>
      </Card>
      <>{props.children}</>
    </div>
  );
};

export default PurchaseOrders;
