import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFileAlt,
  faLayerGroup,
  faQuestion,
  faTasks,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { navigate } from '@reach/router';
import classnames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { ApplicationContext } from '../../context/ApplicationContext';
import { useLocation } from '@reach/router';
import { useQuery } from 'react-query';
import { getAffiliationsForUser, getEventApplication } from './api';
import { parseEventApplicationQuestions } from './EventApplication/EventApplicationQuestionnaire';
import { OrganizationMembership } from './NewApiTypes.generated';
import {
  SectionTypeEnum,
  getPathType
} from './EventApplication/EventApplicationSections';
import SEO from '../Seo';
import { getEventApplicationGeneralInfo } from './EventApplication/EventApplicationGeneralInformation';

const StepBar = () => <div className="bg-gray-200 h-1 w-20 my-auto"></div>;

interface StepLabelProps {
  label: string;
  route: string;
  stepNumber: string;
  isSelected: boolean;
  onSelect: () => void;
  icon: IconProp;
  isDisabled?: boolean;
}
const StepLabel = (props: StepLabelProps) => {
  const applicationContext = useContext(ApplicationContext);
  const linkClasses = classnames({
    'sans-serif uppercase text-gray-400 font-semibold flex flex-col focus:outline-none': true,
    'text-primary': props.isSelected,
    'hover:text-primary hover:underline cursor-pointer':
      !applicationContext.isApplicationSubmitted && !props.isDisabled,
    'text-gray-300 cursor-not-allowed':
      applicationContext.isApplicationSubmitted || props.isDisabled
  });
  const iconContainerClasses = classnames({
    'border-2 rounded-full px-3 py-2 mx-auto mb-1': true,
    'bg-primary': props.isSelected,
    'border-primary':
      !applicationContext.isApplicationSubmitted && !props.isDisabled,
    'border-gray-300':
      applicationContext.isApplicationSubmitted || props.isDisabled
  });
  const iconClasses = classnames({
    'text-xl sans-serif text-center': true,
    'text-white': props.isSelected
  });
  return (
    <div className="mx-2">
      <button
        onClick={props.onSelect}
        className={linkClasses}
        disabled={applicationContext.isApplicationSubmitted || props.isDisabled}
      >
        <div className={iconContainerClasses}>
          <FontAwesomeIcon icon={props.icon} className={iconClasses} />
        </div>
        <p className="sans-serif text-center mt-2">{props.label}</p>
      </button>
    </div>
  );
};

interface EventManagementProps {
  children: React.ReactChildren;
  applicationId: string;
  path: string;
  packageId: string;
  organizationId: string;
  location: any;
}

enum ManageEventApplicationEnum {
  GENERAL_INFORMATION = 'general-information',
  AFFILIATIONS_AND_ATTENDANCE = 'affiliations-and-attendance',
  SECTIONS = 'sections',
  QUESTIONNAIRE = 'questionnaire',
  REVIEW_AND_SUBMIT = 'review-and-submit'
}

export const EventManagementContext = React.createContext<{
  isSomeOrgAffiliationAdded: boolean;
  setIsSomeOrgAffiliationAdded: (isSomeOrgAffiliationAdded: boolean) => void;
  isSomeSectionSelected: boolean;
  setIsSomeSectionSelected: (isSomeSectionSelected: boolean) => void;
  isAllRequiredQuestionsAnswered: boolean;
  setIsAllRequiredQuestionsAnswered: (allQuestionsAnswered: boolean) => void;
  isGeneralInformationFilled: boolean;
  setIsGeneralInformationFilled: (isGeneralInformationFilled: boolean) => void;
}>({
  isSomeOrgAffiliationAdded: false,
  setIsSomeOrgAffiliationAdded: () => {},
  isSomeSectionSelected: false,
  setIsSomeSectionSelected: () => {},
  isAllRequiredQuestionsAnswered: false,
  setIsAllRequiredQuestionsAnswered: () => {},
  isGeneralInformationFilled: false,
  setIsGeneralInformationFilled: () => {}
});

const EventManagement = (props: EventManagementProps) => {
  const [isSomeOrgAffiliationAdded, setIsSomeOrgAffiliationAdded] = useState(
    false
  );
  const [isSomeSectionSelected, setIsSomeSectionSelected] = useState(false);
  const [
    isAllRequiredQuestionsAnswered,
    setIsAllRequiredQuestionsAnswered
  ] = useState(false);
  const [isGeneralInformationFilled, setIsGeneralInformationFilled] = useState(
    false
  );
  const applicationContext = useContext(ApplicationContext);

  useQuery<{
    organizationMemberships?: OrganizationMembership[];
  }>('affiliations', () => getAffiliationsForUser(), {
    onSuccess: data => {
      setIsSomeOrgAffiliationAdded(!!data?.organizationMemberships?.length);
    }
  });

  useQuery(
    ['eventApplication'],
    () => getEventApplication(props.applicationId),
    {
      onSuccess: data => {
        applicationContext.setIsApplicationSubmitted(
          data.application.status && data.application.status !== 'STARTED'
        );

        setIsAllRequiredQuestionsAnswered(
          Object.entries(
            parseEventApplicationQuestions(
              data.application.event.applicationQuestions,
              data
            )
          ).every(([key, value]) => {
            const isRequired = !!data.application.event.applicationQuestions.find(
              (eaq: any) => eaq.id === key
            )?.question?.required;
            if (!isRequired) {
              return true;
            }
            return typeof value === 'string' ? !!value?.trim?.() : !!value;
          })
        );

        const pathType = getPathType(data?.application);
        const firstYearPath = data.application.event.paths.find(
          (p: any) => p.name === SectionTypeEnum.FIRST_YEAR
        );
        const advancedPath = data.application.event.paths.find(
          (p: any) => p.name === SectionTypeEnum.ADVANCED
        );
        const path =
          pathType === SectionTypeEnum.FIRST_YEAR
            ? firstYearPath
            : advancedPath;
        const requestedGroupings = data?.application?.requestedGroupings.map(
          (rg: any) => rg.eventPathGroupingId
        );
        const requestedSections = data?.application?.requestedSections.map(
          (rs: any) => ({
            id: rs.eventSectionId,
            ranking: rs.ranking
          })
        );
        const isSomeSectionSelected =
          path.applicationSelectionLevel === 'GROUPINGS'
            ? requestedGroupings.length > 0
            : requestedSections.length > 0;

        setIsSomeSectionSelected(isSomeSectionSelected);

        setIsGeneralInformationFilled(
          Object.entries(
            getEventApplicationGeneralInfo(data.application, true)
          ).some(([_key, value]) => !!value)
        );
      }
    }
  );
  const [currentStep, setCurrentStep] = useState(
    ManageEventApplicationEnum.GENERAL_INFORMATION
  );

  const location = useLocation();

  useEffect(() => {
    [
      ManageEventApplicationEnum.GENERAL_INFORMATION,
      ManageEventApplicationEnum.AFFILIATIONS_AND_ATTENDANCE,
      ManageEventApplicationEnum.SECTIONS,
      ManageEventApplicationEnum.QUESTIONNAIRE,
      ManageEventApplicationEnum.REVIEW_AND_SUBMIT
    ].forEach(step => {
      if (location.pathname.indexOf(step) !== -1) {
        setCurrentStep(step);
      }
    });
  }, [location]);

  const isTabAvailable = (tab: ManageEventApplicationEnum) => {
    if (tab === ManageEventApplicationEnum.REVIEW_AND_SUBMIT) {
      return (
        isSomeOrgAffiliationAdded &&
        isSomeSectionSelected &&
        isAllRequiredQuestionsAnswered &&
        isGeneralInformationFilled
      );
    }
    if (tab === ManageEventApplicationEnum.QUESTIONNAIRE) {
      return (
        isSomeOrgAffiliationAdded &&
        isSomeSectionSelected &&
        isGeneralInformationFilled
      );
    }
    if (tab === ManageEventApplicationEnum.SECTIONS) {
      return isSomeOrgAffiliationAdded && isGeneralInformationFilled;
    }
    if (tab === ManageEventApplicationEnum.AFFILIATIONS_AND_ATTENDANCE) {
      return isGeneralInformationFilled;
    }
    return true;
  };

  return (
    <>
      <SEO title="Event Application" />
      <div className="shadow bg-white p-8 -mt-8 -mx-8">
        <h1 className="text-primary text-2xl font-bold sans-serif text-center mb-4 uppercase">
          Manage Event Application
        </h1>
        <div className="flex justify-center">
          <StepLabel
            label="General Information"
            route="general-information"
            stepNumber="1"
            isSelected={
              currentStep === ManageEventApplicationEnum.GENERAL_INFORMATION
            }
            icon={faFileAlt}
            isDisabled={
              !isTabAvailable(ManageEventApplicationEnum.GENERAL_INFORMATION)
            }
            onSelect={() => {
              navigate(ManageEventApplicationEnum.GENERAL_INFORMATION);
            }}
          />
          <StepBar />
          <StepLabel
            label="Affiliations"
            route="affiliations-and-attendance"
            stepNumber="2"
            isSelected={
              currentStep ===
              ManageEventApplicationEnum.AFFILIATIONS_AND_ATTENDANCE
            }
            icon={faUsers}
            isDisabled={
              !isTabAvailable(
                ManageEventApplicationEnum.AFFILIATIONS_AND_ATTENDANCE
              )
            }
            onSelect={() => {
              navigate(ManageEventApplicationEnum.AFFILIATIONS_AND_ATTENDANCE);
            }}
          />
          <StepBar />
          <StepLabel
            label="Sections"
            route="sections"
            stepNumber="3"
            isSelected={currentStep === ManageEventApplicationEnum.SECTIONS}
            icon={faLayerGroup}
            isDisabled={!isTabAvailable(ManageEventApplicationEnum.SECTIONS)}
            onSelect={() => {
              navigate(ManageEventApplicationEnum.SECTIONS);
            }}
          />
          <StepBar />
          <StepLabel
            label="Questionnaire"
            route="questionnaire"
            stepNumber="4"
            isSelected={
              currentStep === ManageEventApplicationEnum.QUESTIONNAIRE
            }
            icon={faQuestion}
            isDisabled={
              !isTabAvailable(ManageEventApplicationEnum.QUESTIONNAIRE)
            }
            onSelect={() => {
              navigate(ManageEventApplicationEnum.QUESTIONNAIRE);
            }}
          />
          <StepBar />
          <StepLabel
            label="Review and Submit"
            route="review-and-submit"
            stepNumber="5"
            isSelected={
              currentStep === ManageEventApplicationEnum.REVIEW_AND_SUBMIT
            }
            icon={faTasks}
            isDisabled={
              !isTabAvailable(ManageEventApplicationEnum.REVIEW_AND_SUBMIT)
            }
            onSelect={() => {
              navigate(ManageEventApplicationEnum.REVIEW_AND_SUBMIT);
            }}
          />
        </div>
      </div>
      <EventManagementContext.Provider
        value={{
          isSomeOrgAffiliationAdded,
          setIsSomeOrgAffiliationAdded,
          isSomeSectionSelected,
          setIsSomeSectionSelected,
          isAllRequiredQuestionsAnswered,
          setIsAllRequiredQuestionsAnswered,
          isGeneralInformationFilled,
          setIsGeneralInformationFilled
        }}
      >
        <div className="mt-4">{props.children}</div>
      </EventManagementContext.Provider>
    </>
  );
};

export default EventManagement;
