import { navigate } from 'gatsby';
import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { AuthContext } from '../../../context/AuthContext';
import { toast } from 'react-toastify';
import Card from '../../common/Card';
import LoadingDialog from '../../common/LoadingDialog';
import Affiliations from '../Affiliations/Affiliations';
import { getEventApplication } from '../api';
import ApplicationSaveControls from '../ApplicationSaveControls';
import EventApplicationPreviousAttendance from './EventApplicationPreviousAttendance';
import EventApplicationHeader from './EventApplicationHeader';
import { EventManagementContext } from '../EventManagement';

interface EventApplicationAffiliationsAttendanceProps {
  path: string;
  children: React.ReactChildren;
  applicationId: string;
}

const EventApplicationAffiliationsAttendance = (
  props: EventApplicationAffiliationsAttendanceProps
) => {
  const auth = useContext(AuthContext);
  const { isSomeOrgAffiliationAdded } = useContext(EventManagementContext);

  const description = `Please affiliate yourself with your most current school. Only if you work district-wide should you affiliate yourself with the district. Otherwise, your specific school should be listed. Based on school affiliations, your NYC Department of Education affiliation status is also shown below. Please review your affiliations.`;

  const {
    data: eventApplication,
    isLoading: eventApplicationLoading,
    refetch
  } = useQuery(
    ['eventApplication'],
    () => getEventApplication(props.applicationId),
    {
      onSuccess: data => console.log('the event app data', data)
    }
  );

  const save = (shouldNavigate = false) => {
    toast.success('Your application has been saved');
    if (shouldNavigate) {
      navigate(`../sections`);
    }
  };

  return (
    <>
      <Card>
        <EventApplicationHeader
          title="Affiliations"
          description={description}
        />
        {eventApplicationLoading ? <LoadingDialog /> : null}
        <div className="mb-4">
          <Affiliations hideShadow={true} authState={auth.authState} />
        </div>
        {/* <div className="mb-4">
          <EventApplicationPreviousAttendance
            refetch={refetch}
            eventApplicationId={props.applicationId}
            eventHistory={eventApplication?.application?.user?.eventHistory}
            legacyEventAttendees={eventApplication?.legacyEventAttendees}
          />
        </div> */}
        <ApplicationSaveControls
          saveApplication={() => save()}
          saveApplicationAndContinue={() => save(true)}
          isDisabledContinue={!isSomeOrgAffiliationAdded}
        />
        {!isSomeOrgAffiliationAdded && (
          <p className="sans-serif text-gray-400 w-full text-right mt-2">
            You must add at least one affiliation to continue.
          </p>
        )}
      </Card>
    </>
  );
};

export default EventApplicationAffiliationsAttendance;
