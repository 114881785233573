import React, { useState } from 'react';
import SEO from '../Seo';
import Card from '../common/Card';
import { useQuery } from 'react-query';
import { getEventResources } from './api';
import LoadingDialog from '../common/LoadingDialog';
import SearchInput from '../SearchInput';
import { useDebouncedCallback } from 'use-debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';

type Resource = {
  id: string;
  title: string;
  description: string;
  url: string;
  order: number;
  sections: { id: string; title: string; displayTitle: string }[];
};

type EventResourcesProps = {
  eventId: string;
  path: string;
  location?: any;
};

const EventResources = (props: EventResourcesProps) => {
  const params = new URLSearchParams(props.location?.search);
  const eventSectionId = params.has('eventSectionId')
    ? String(params.get('eventSectionId'))
    : undefined;
  const eaId = params.has('eaId') ? String(params.get('eaId')) : undefined;

  const { data, isLoading } = useQuery(['eventResources'], () =>
    getEventResources({
      eventId: props.eventId,
      eventSectionId,
      eaId
    })
  );

  const [searchTerm, setSearchTerm] = useState('');

  const debounced = useDebouncedCallback(
    searchTerm => setSearchTerm(searchTerm),
    300
  );

  const filteredResources = ((data?.resources?.filter?.((resource: Resource) =>
    resource.title.toLowerCase().includes(searchTerm)
  ) || []) as Resource[]).sort((a, b) => a.order - b.order);

  const groupedResources = filteredResources.reduce((acc, resource) => {
    resource.sections.forEach(section => {
      if (!acc[section.id]) {
        acc[section.id] = {
          title: section.displayTitle || section.title,
          resources: []
        };
      }
      acc[section.id].resources.push(resource);
    });
    return acc;
  }, {} as { [key: string]: { title: string; resources: Resource[] } });

  return (
    <>
      <SEO title={`Event Resources`} />
      <div className="w-1/3 mb-4">
        <SearchInput
          placeholder="Search Resources"
          onChange={(e: any) =>
            debounced.callback(e.target.value.toLowerCase())
          }
        />
      </div>
      <div className="my-4">
        {searchTerm && (
          <p className="italic text-gray-500">
            Showing results for filter "{searchTerm}"
          </p>
        )}
      </div>
      <Card>
        <div className="lg:mx-8">
          <div className="mb-8">
            <div className="flex justify-between">
              <div className="">
                <h2 className="text-2xl font-semibold sans-serif text-primary">
                  Resources
                </h2>
              </div>
              <div className="">
                <div className="flex flex-row my-auto ml-8 text-center">
                  <span className="px-3 py-1 text-sm text-blue-100 rounded-full sans-serif bg-primary">
                    {Object.values(groupedResources)?.reduce(
                      (acc, group) => acc + group.resources.length,
                      0
                    ) || 0}{' '}
                    Items
                  </span>
                </div>
              </div>
            </div>
            {isLoading && (
              <div className="flex justify-center items-center h-52">
                <LoadingDialog />
              </div>
            )}
            {!isLoading && !!filteredResources?.length && (
              <>
                {Object.keys(groupedResources).map((groupKey, j, arr) => {
                  return (
                    <div className="mt-4" key={groupKey}>
                      <h3 className="text-primary font-bold text-xl border-b border-gray-200">
                        {groupedResources[groupKey].title}
                      </h3>
                      <ul>
                        {groupedResources[groupKey].resources.map(
                          (item: Resource, i: any) => {
                            return (
                              <li
                                className={classNames({
                                  'text-primary font-semibold text-lg p-4 ': true,
                                  'border-b border-gray-200':
                                    i !==
                                    groupedResources[groupKey].resources
                                      .length -
                                      1
                                })}
                                key={item.id}
                              >
                                <div className="">
                                  <a
                                    href={item.url}
                                    target="_blank"
                                    className="flex justify-between hover:underline"
                                  >
                                    <div>
                                      <FontAwesomeIcon icon={faFile} />
                                      <span className="sans-serif ml-4">
                                        {item.title}
                                      </span>
                                    </div>
                                  </a>
                                  <div className="text-sm truncate max-w-xl font-normal">
                                    {item.description}
                                  </div>
                                </div>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  );
                })}
              </>
            )}
            {!isLoading && !filteredResources?.length && (
              <div className="flex justify-center items-center h-52">
                <p className="sans-serif text-gray-500">No resources found</p>
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default EventResources;
