import React from 'react';
import { OptionType } from '../../../constants/select';
import Card from '../../common/Card';
import { FormField } from '../../common/Form';
import EventApplicationField from './EventApplicationField';

interface EventApplicationQuestionnaireProps {
  application: any;
}

const EventApplicationPayment = (props: EventApplicationQuestionnaireProps) => {
  const { application } = props;
  const due = application?.event?.finance?.due || 0;
  const paymentOptions = application?.event?.finance?.paymentOptions || [];
  const options: OptionType[] = paymentOptions.map((o: any) => ({
    value: o.id,
    label: o.label
  }));
  return (
    <Card className="shadow-none">
      <h4 className="text-gray-600 mt-1 text-lg mb-2">
        If your application is accepted payment will be due within{' '}
        <b>{`${due} ${due > 1 ? 'days' : 'day'}`}.</b> If we do not receive
        payment or request for a time extension within this time period your
        application will be automatically <b>put on our waitlist.</b>
      </h4>
      {application.status === 'STARTED' ? (
        <FormField
          name="paymentOption"
          type="select"
          className="w-1/4"
          options={options}
          isLoading={false}
        />
      ) : (
        <EventApplicationField label="Payment Option">
          {options.find(
            (o: OptionType) => o.value === application?.paymentOptionId
          )?.label || 'N/A'}
        </EventApplicationField>
      )}
    </Card>
  );
};

export default EventApplicationPayment;
