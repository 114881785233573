import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { AuthContext } from '../../../context/AuthContext';
import Button from '../../common/Button';
import Card from '../../common/Card';
import Affiliations from '../Affiliations/Affiliations';
import { getEventApplication, updateEventApplication } from '../api';
import EventApplicationGeneralInformationPreview from './EventApplicationGeneralInformationPreview';
import EventApplicationHeader from './EventApplicationHeader';
import EventApplicationPreviousAttendance from './EventApplicationPreviousAttendance';
import EventApplicationQuestionnairePreview from './EventApplicationQuestionnaireReview';
import EventApplicationSectionsPreview from './EventApplicationSectionsPreview';
import { toast } from 'react-toastify';
import { navigate } from 'gatsby';
import { ApplicationContext } from '../../../context/ApplicationContext';
import EventApplicationPayment from './EventApplicationPayment';
import { FormWrapper } from '../../common/Form';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { EventManagementContext } from '../EventManagement';

interface EventApplicationReviewSubmitProps {
  path: string;
  children: React.ReactChildren;
  applicationId: string;
}

export type ReviewAndSubmitForm = {
  paymentOption: string;
};

const ReviewAndSubmitSchema: Yup.SchemaOf<ReviewAndSubmitForm> = Yup.object().shape(
  {
    paymentOption: Yup.string().required('Payment option is required')
  }
);

const EventApplicationReviewSubmit = (
  props: EventApplicationReviewSubmitProps
) => {
  const eventManagementContext = useContext(EventManagementContext);
  const isAllPagesFilled =
    eventManagementContext.isAllRequiredQuestionsAnswered &&
    eventManagementContext.isSomeOrgAffiliationAdded &&
    eventManagementContext.isSomeSectionSelected &&
    eventManagementContext.isGeneralInformationFilled;
  const applicationContext = useContext(ApplicationContext);
  const auth = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const { data } = useQuery(
    ['eventApplication'],
    () => getEventApplication(props.applicationId),
    {
      onSuccess: data => {
        formik.setValues(
          {
            paymentOption: data?.application?.paymentOptionId
          },
          true
        );
      }
    }
  );

  const mutation = useMutation(
    ['saveGeneralInformation'],
    updateEventApplication
  );

  const submit = async (values: any) => {
    setLoading(true);
    await mutation.mutate(
      {
        segment: 'REVIEW_AND_SUBMIT',
        applicationId: props.applicationId,
        data: {
          paymentOption: values.paymentOption
        }
      },
      {
        onSuccess: () => {
          if (data.state === 'BAD_REQUEST') {
            toast.error(data.message || 'Something went wrong');
          } else {
            toast.success('Your application has been submitted');
            setLoading(false);

            navigate('/members/profile');
          }
        }
      }
    );
  };
  const formik = useFormik<ReviewAndSubmitForm>({
    validationSchema: ReviewAndSubmitSchema,
    initialValues: {
      paymentOption: ''
    },
    onSubmit: submit
  });
  return (
    <>
      <Card>
        <EventApplicationHeader
          title="Review and Submit"
          description={data?.application?.event?.name}
        />
        {data && (
          <>
            <div className="w-full my-4 border"></div>
            <EventApplicationHeader title="General Information" size="xs" />
            <EventApplicationGeneralInformationPreview
              application={data.application}
            />
            <div className="w-full my-4 border"></div>
            <EventApplicationHeader title="Affiliations" size="xs" />
            <Affiliations
              isReview={true}
              hideShadow={true}
              authState={auth.authState}
            />
            {/* <div className="mb-4">
              <EventApplicationPreviousAttendance
                isReview={true}
                eventHistory={data?.application?.user?.eventHistory}
                legacyEventAttendees={data?.legacyEventAttendees}
              />
            </div> */}
            <div className="w-full my-4 border"></div>
            <EventApplicationHeader title="Sections" size="xs" />
            <EventApplicationSectionsPreview application={data.application} />

            <div className="w-full my-4 border"></div>
            <EventApplicationHeader title="Questionnaire" size="xs" />
            <EventApplicationQuestionnairePreview
              application={data?.application}
            />
            <FormWrapper
              formik={formik}
              isLoading={loading}
              hideActionButtons={true}
              className="px-3 py-4 border border-gray-300 bg-gray-100 rounded-xl"
            >
              <EventApplicationHeader title="Payment" size="xs" />
              <EventApplicationPayment
                application={data?.application}
              ></EventApplicationPayment>
              {!applicationContext.isApplicationSubmitted && (
                <div className="my-auto flex flex-col items-end">
                  <div className="mr-4">
                    <Button
                      text="Submit"
                      type="submit"
                      disabled={!formik.isValid || !isAllPagesFilled}
                      isLoading={loading}
                    />
                  </div>
                  {!eventManagementContext.isAllRequiredQuestionsAnswered && (
                    <p className="mr-4 mt-1 text-gray-600 sans-serif">
                      Please answer all the required questions to submit the
                      application
                    </p>
                  )}
                  {!eventManagementContext.isSomeOrgAffiliationAdded && (
                    <p className="mr-4 mt-1 text-gray-600 sans-serif">
                      Please add at least one organization affiliation to submit
                      the application
                    </p>
                  )}
                  {!eventManagementContext.isSomeSectionSelected && (
                    <p className="mr-4 mt-1 text-gray-600 sans-serif">
                      Please select at least one section to submit the
                      application
                    </p>
                  )}
                  {!eventManagementContext.isGeneralInformationFilled && (
                    <p className="mr-4 mt-1 text-gray-600 sans-serif">
                      Please fill general information to submit the application
                    </p>
                  )}
                </div>
              )}
            </FormWrapper>
          </>
        )}
      </Card>
    </>
  );
};

export default EventApplicationReviewSubmit;
