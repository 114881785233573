import React, { useEffect, useState } from 'react';
import Card from '../../common/Card';
import * as _ from 'lodash';
import { SectionTypeEnum, getPathType } from './EventApplicationSections';

interface EventApplicationSectionsProps {
  application: any;
}

const EventApplicationSectionsPreview = (
  props: EventApplicationSectionsProps
) => {
  const [type, setType] = useState<SectionTypeEnum | null>(null);
  const [firstYearPath, setFirstYearPath] = useState<any>(null);
  const [advancedPath, setAdvancedPath] = useState<any>(null);

  const [requireRankedSelection, setRequireRankedSelection] = useState<any>(
    false
  );
  useEffect(() => {
    if (props.application) {
      setType(getPathType(props.application));
      setFirstYearPath(
        props.application.event.paths.find(
          (p: any) => p.name === SectionTypeEnum.FIRST_YEAR
        )
      );
      setAdvancedPath(
        props.application.event.paths.find(
          (p: any) => p.name === SectionTypeEnum.ADVANCED
        )
      );
    }
  }, [props.application]);

  const getPath = () =>
    type === SectionTypeEnum.FIRST_YEAR ? firstYearPath : advancedPath;

  useEffect(() => {
    const path = getPath();
    if (path) {
      const { requireRankedSelection } = path;
      setRequireRankedSelection(requireRankedSelection);
    }
  }, [firstYearPath, advancedPath]);

  const renderSelectionComponent = () => {
    const path = getPath();
    return (
      <>
        {path.applicationSelectionLevel === 'GROUPINGS' && (
          <div className="font-bold">
            Selected group:{' '}
            {
              props.application.requestedGroupings?.[0]?.eventPathGrouping
                ?.title
            }
          </div>
        )}
        {/* if there is no required ranking we need to select single section for advanced */}
        {path.applicationSelectionLevel === 'SECTIONS' &&
          !requireRankedSelection && (
            <div className="font-bold">
              Selected section:{' '}
              {props.application.requestedSections[0]?.eventSections
                ?.displayTitle ||
                props.application.requestedSections[0]?.eventSections?.title}
            </div>
          )}
        {/* if there is required ranking we need to select multiple sections for advanced with ranking */}
        {path.applicationSelectionLevel === 'SECTIONS' &&
          requireRankedSelection && (
            <div>
              {_.sortBy(props.application.requestedSections, 'ranking').map(
                (section: any) => (
                  <div key={section.id} className="font-bold">{`${
                    section.ranking
                  }: ${section.eventSections.displayTitle ||
                    section.eventSections.title}`}</div>
                )
              )}
            </div>
          )}
      </>
    );
  };

  return (
    <Card className="shadow-none">
      {firstYearPath || advancedPath ? (
        <>
          <h2 className="text-primary mb-2 font-bold text-lg">
            {type === SectionTypeEnum.FIRST_YEAR
              ? firstYearPath?.name
              : advancedPath?.name}
          </h2>
          {renderSelectionComponent()}
        </>
      ) : null}
    </Card>
  );
};

export default EventApplicationSectionsPreview;
