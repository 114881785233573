import React, { useEffect, useRef, useState } from 'react';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import { finishEventPayment, getEventPayment } from '../api';
import MembersPageTitle from '../MembersPageTitle';
import Card from '../../common/Card';
import Button from '../../common/Button';

interface ConferenceDaysCheckoutSuccessProps {
  path: string;
}
const EventApplicationCheckoutSuccess = (
  props: ConferenceDaysCheckoutSuccessProps
) => {
  const { eventPaymentId, source } = queryString.parse(location.search);
  const allUrlArgs = queryString.parse(location.search);
  if (!eventPaymentId) {
    navigate(`/members/event-cart/checkout`);
  }

  const [paymentInProgress, setPaymentInProgress] = useState(true);
  const intervalRef: { current: NodeJS.Timeout | null } = useRef(null);

  const loadEventPayment = async (intervalRef: any) => {
    if (source === 'purchase-order') {
      return setPaymentInProgress(false);
    }
    const result =
      source === 'stripe'
        ? await getEventPayment(eventPaymentId as string)
        : await finishEventPayment(
            eventPaymentId as string,
            allUrlArgs.batchno as string,
            allUrlArgs.tx as string,
            allUrlArgs.custcode as string,
            allUrlArgs.ref3val1 as string
          );
    if (result.state === 'ERROR') {
      navigate(`/members/event-cart/checkout`);
    } else if (result?.eventPayment?.status === 'PAID') {
      clearInterval(intervalRef.current as NodeJS.Timeout);
      setPaymentInProgress(false);
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(async () => {
      loadEventPayment(intervalRef);
    }, 2000);
    loadEventPayment(intervalRef);

    return () => {
      clearInterval(intervalRef.current as NodeJS.Timeout);
    };
  }, []);

  return (
    <>
      <MembersPageTitle title="Event Application Payment" />
      <Card className="mt-4 flex flex-col items-center">
        {paymentInProgress ? (
          <>
            <div className="p-4 text-primary flex justify-center italic">
              Your payment is processing, please wait...
            </div>
          </>
        ) : (
          <>
            <div className="p-4 text-primary flex justify-center">
              {source === 'purchase-order'
                ? 'Thank you for uploading your purchase order. We will review and notify you once approved.'
                : 'Your purchase has been successfully processed.'}
            </div>
            <Button
              text="Back to Event Applications"
              onClick={() => navigate(`/members/events/applications`)}
            />
          </>
        )}
      </Card>
    </>
  );
};

export default EventApplicationCheckoutSuccess;
