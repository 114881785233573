import React, { useEffect, useState } from 'react';
import SEO from '../Seo';
import Card from '../common/Card';
import { useQuery } from 'react-query';
import {
  getAffiliationsForUser,
  getOrganizationResources,
  getSchoolYears
} from './api';
import LoadingDialog from '../common/LoadingDialog';
import SearchInput from '../SearchInput';
import { useDebouncedCallback } from 'use-debounce';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import Select, { Styles } from 'react-select';
import { displayGradeLevels } from '../../util/grades';

const customStylesForSelect: Partial<Styles> = {
  control: (provided, state) => ({
    ...provided,
    padding: '6px 12px',
    backgroundColor: '#E2E8F0',
    borderRadius: '9999px',
    border: 'none',
    '&:hover': {
      borderColor: 'none'
    }
  })
};

function getOptionsForSelect(data: Array<{ id: string; name: string }>) {
  const options: Array<{
    value: string;
    label: string;
  }> = data.map((year: any) => ({
    value: year.id,
    label: year.name
  }));

  return options.sort((a, b) => {
    return a.label.localeCompare(b.label);
  });
}

function getMostRecentSchoolYear(schoolYears: any) {
  const formattedSchoolYears = getOptionsForSelect(schoolYears);

  return formattedSchoolYears[formattedSchoolYears.length - 1];
}

type Resource = {
  id: string;
  title: string;
  schoolYearId: string;
  organizationId: string;
  dayType: string;
  description: string;
  grades: {
    name: string;
  }[];
  url: string;
};

const gradeOrder = [
  'Pre-K',
  'K',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12'
];

function getGradeIndex(grade: string): number {
  return gradeOrder.indexOf(grade);
}

const OrganizationResources = () => {
  const { isLoading: schoolYearsLoading, data: schoolYearsResult } = useQuery(
    'schoolYears',
    getSchoolYears
  );
  const {
    isLoading: organizationsLoading,
    data: organizatinsResult
  } = useQuery('organizations', getAffiliationsForUser, {});

  const [schoolYear, setSchoolYear] = useState('');
  const [organization, setOrganization] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const { data, isLoading } = useQuery(
    ['organizationResources', schoolYear, organization],
    () =>
      getOrganizationResources({
        schoolYearId: schoolYear,
        organizationId: organization
      }),
    {
      enabled: !!organization
    }
  );
  const debounced = useDebouncedCallback(
    searchTerm => setSearchTerm(searchTerm),
    300
  );

  const filteredResources = (
    data?.resources?.filter?.((resource: Resource) =>
      resource.title.toLowerCase().includes(searchTerm)
    ) || []
  )
    .map((resource: Resource) => {
      resource.grades.sort(
        (a, b) => getGradeIndex(a.name) - getGradeIndex(b.name)
      );
      return resource;
    })
    .sort((a: Resource, b: Resource) => {
      if (a.grades.length === 0 && b.grades.length === 0) return 0; // Both have no grades
      if (a.grades.length === 0) return 1; // 'a' has no grades, put it after 'b'
      if (b.grades.length === 0) return -1; // 'b' has no grades, put it after 'a'

      const gradeA = a.grades[0].name;
      const gradeB = b.grades[0].name;

      return getGradeIndex(gradeA) - getGradeIndex(gradeB);
    });

  const orgOptions =
    getOptionsForSelect(organizatinsResult?.organizations || []) || [];

  useEffect(() => {
    if (!organizationsLoading && !organization) {
      if (orgOptions.length) {
        setOrganization(orgOptions[0].value);
      }
    }
  }, [organizationsLoading]);

  return (
    <>
      <SEO title={`Organization Resources`} />
      <div className="w-2/3 mb-4 flex items-end gap-3">
        <div>
          <SearchInput
            placeholder="Search Resources"
            onChange={(e: any) =>
              debounced.callback(e.target.value.toLowerCase())
            }
          />
        </div>
        <div className="w-64">
          <Select
            placeholder="Select School Year"
            styles={customStylesForSelect}
            options={
              getOptionsForSelect(schoolYearsResult?.schoolYears || []) || []
            }
            onChange={option => {
              if (option?.value) {
                setSchoolYear(option.value.toString());
              } else {
                setSchoolYear('');
              }
            }}
            isLoading={schoolYearsLoading}
            isClearable
          />
        </div>
        <div className="w-64">
          <Select
            placeholder="Select Organizations"
            styles={customStylesForSelect}
            options={orgOptions}
            value={
              organization
                ? {
                    value: organization,
                    label: String(
                      orgOptions.find(el => el.value === organization)?.label ||
                        ''
                    )
                  }
                : undefined
            }
            onChange={option => {
              if (option?.value) {
                setOrganization(option.value.toString());
              } else {
                setOrganization('');
              }
            }}
            isLoading={organizationsLoading}
          />
        </div>
      </div>
      <div className="my-4">
        {searchTerm && (
          <p className="italic text-gray-500">
            Showing results for filter "{searchTerm}"
          </p>
        )}
      </div>

      <Card>
        <div className="lg:mx-8">
          <div className="mb-8">
            <div className="flex justify-between">
              <div className="">
                <h2 className="text-2xl font-semibold sans-serif text-primary">
                  Resources
                </h2>
              </div>
              <div className="">
                <div className="flex flex-row my-auto ml-8 text-center">
                  <span className="px-3 py-1 text-sm text-blue-100 rounded-full sans-serif bg-primary">
                    {filteredResources?.length || 0} Items
                  </span>
                </div>
              </div>
            </div>
            {(isLoading || organizationsLoading) && (
              <div className="flex justify-center items-center h-52">
                <LoadingDialog />
              </div>
            )}
            {!isLoading &&
              !organizationsLoading &&
              !!filteredResources?.length && (
                <div className="mt-4">
                  <ul>
                    {filteredResources.map((item: Resource, i: any) => {
                      return (
                        <li
                          className="text-primary font-semibold text-lg p-4 border-b border-gray-200"
                          key={item.id}
                        >
                          <div className="">
                            <a
                              href={item.url}
                              target="_blank"
                              className="flex justify-between hover:underline"
                            >
                              <div>
                                <FontAwesomeIcon icon={faFile} />
                                <span className="sans-serif ml-4">
                                  {item.title}
                                </span>
                              </div>
                            </a>
                            <div className="text-sm truncate max-w-xl font-semibold">
                              {displayGradeLevels(
                                item.grades.map(({ name }) => name) as any
                              )}
                            </div>
                            <div className="text-sm truncate max-w-xl font-normal">
                              {item.description}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            {!isLoading && !organizationsLoading && !filteredResources?.length && (
              <div className="flex justify-center items-center h-52">
                <p className="sans-serif text-gray-500">No resources found</p>
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default OrganizationResources;
