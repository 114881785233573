import React, { useContext, useEffect, useState } from 'react';
import {
  faExternalLinkAlt,
  faFileVideo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'querystring';
import { useQuery } from 'react-query';
import { getVideoCollectionContent } from './Queries';
import { getConnectLink } from '../../util';
import { AuthContext } from '../../context/AuthContext';

type Video = {
  id: string;
  title: string;
  vimeoId: string;
  link: string;
  description: string;
  documents: { title: string; downloadLink: string; documentId: string }[];
};

interface VideoCollectionContentProps {
  path: string;
  collectionName: string;
  location: any;
}
const VideoCollectionContent = (props: VideoCollectionContentProps) => {
  const auth = useContext(AuthContext);
  const [currentVideo, setCurrentVideo] = useState<Video>();

  const { isLoading, error, data } = useQuery('videoCollectionContent', () =>
    getVideoCollectionContent(props.collectionName)
  );

  const queryParams = queryString.parse(props.location.search);

  useEffect(() => {
    if (data && data.videos && data.videos.length) {
      if (queryParams) {
        const videoId = queryParams['?selectedVideo'];
        setCurrentVideo(data.videos.find((v: Video) => v.id === videoId));
      } else {
        setCurrentVideo(data.videos[0]);
      }
    }
  }, [data]);

  return (
    <div className="flex">
      {data && currentVideo && (
        <>
          <section className="w-2/3">
            <section
              className="bg-primary p-8 flex flex-col"
              style={{ height: '500px' }}
            >
              <h1 className="text-4xl font-bold text-blue-100 mb-4">
                {data.name}
              </h1>
              <iframe
                src={`https://player.vimeo.com/video/${currentVideo.vimeoId}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                width="100%"
                height="100%"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title={currentVideo.title}
              ></iframe>
            </section>
            <section className="p-8">
              <h2 className="text-primary text-3xl sans-serif font-bold">
                {currentVideo.title}
              </h2>
              {currentVideo.description ? (
                <p className="text-gray-600 sans-serif text-lg mb-20 mt-4">
                  {currentVideo.description}
                </p>
              ) : (
                <p className="text-gray-500 italic sans-serif text-lg mb-20 mt-4">
                  No description provided
                </p>
              )}
              {!!currentVideo?.documents?.length && (
                <>
                  <p className="font-bold text-gray-500 text-xl sans-serif mb-2">
                    Supporting Documents
                  </p>
                  <>
                    {currentVideo.documents.map((document, i: number) => {
                      return (
                        <a
                          href={getConnectLink(
                            document.downloadLink,
                            auth.authState
                          )}
                          target="_blank"
                          key={i}
                        >
                          <div className="p-4 bg-white shadow-md mb-2 rounded-lg">
                            <span className="text-primary sans-serif text-lg hover:underline hover:text-primary-dark">
                              {document.title}
                            </span>
                            <FontAwesomeIcon
                              className="text-gray-400 text-sm ml-2 "
                              icon={faExternalLinkAlt}
                            />
                          </div>
                        </a>
                      );
                    })}
                  </>
                </>
              )}
            </section>
          </section>
          <section className="w-1/3">
            <div className="bg-white h-full rounded-lg p-4">
              <ul>
                {data.videos.map((video: Video, i: number) => (
                  <li
                    className="p-3 border-b border-gray-200"
                    onClick={() => setCurrentVideo(video)}
                    key={i}
                  >
                    <p className="text-primary font-bold text-lg sans-serif cursor-pointer hover:underline">
                      <FontAwesomeIcon icon={faFileVideo} className="mr-2" />
                      {video.title}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </section>
        </>
      )}
    </div>
  );
};

export default VideoCollectionContent;
