import classnames from 'classnames';
import React from 'react';
import {
  calculatePdSessionCostRange,
  flatPolyfill,
  formatAsCurrency
} from '../../util';
import {
  Package,
  PackageRequest,
  PackageRequestEvent,
  PackageRequestGroup,
  PackageRequestGroupSection,
  PackageRequestInstitute,
  PackageRequestSchoolBasedPDSession,
  PackageSchoolBasedPDSession
} from './NewApiTypes.generated';
import LoadingDialog from '../common/LoadingDialog';

export const prepareCurrencyCalculation = (value: number) => {
  return value ? value / 100 : 0;
};

const getTotalCostRange = (sessionCosts: number[], travelCosts: number) => {
  const totalCostRange = [
    sessionCosts[0] + travelCosts,
    sessionCosts[1] + travelCosts
  ];
  return totalCostRange;
};

export const getTravelCost = (
  totalDays: number,
  totalRemoteDays: number,
  packageCosts: PackageCosts
) => {
  if (packageCosts.travelCost === 'ACTUAL_COST') {
    return (
      prepareCurrencyCalculation(packageCosts.actualCostAmount) *
      (totalDays - totalRemoteDays)
    );
  } else if (packageCosts.travelCost === 'FIXED_COST') {
    return (
      prepareCurrencyCalculation(packageCosts.fixedCostAmount) *
      (totalDays - totalRemoteDays)
    );
  } else {
    return 0;
  }
};

export type PackageCosts = {
  packageMinimum: number;
  staffDeveloperLow: number;
  staffDeveloperHigh: number;
  travelCost: 'ACTUAL_COST' | 'FIXED_COST' | 'NO_COST';
  actualCostAmount: number;
  fixedCostAmount: number;
};

export const getPackageCosts = (
  packageRequest: PackageRequest
): PackageCosts => {
  let staffDeveloperLow = 0;
  let staffDeveloperHigh = 0;
  for (const pdSession of flatPolyfill(
    packageRequest.package.pdSessionGroupings.map(g => g?.pdSessions || [])
  )) {
    const [low, high] = calculatePdSessionCostRange(
      packageRequest,
      ((packageRequest.requestedPDSessions || []) as unknown) as Partial<
        PackageRequestSchoolBasedPDSession
      >[],
      pdSession as PackageSchoolBasedPDSession
    );
    staffDeveloperLow += low;
    staffDeveloperHigh += high;
  }

  return {
    packageMinimum: packageRequest.package?.minimumAmount || 0,
    staffDeveloperLow,
    staffDeveloperHigh,
    travelCost: packageRequest.package.travelCost,
    actualCostAmount: packageRequest.package.actualCostAmount || 0,
    fixedCostAmount: packageRequest.package.fixedCostAmount || 0
  };
};

export const getPDSessionCostRange = (
  requestedPDSessions: Partial<PackageRequestSchoolBasedPDSession>[],
  packageCosts: PackageCosts
) => {
  const totalDays = [...requestedPDSessions]
    .map(
      session =>
        (session.overrideDate
          ? session.overrideAmount
          : session.requestedDays) || 0
    )
    .reduce((a: number, b: number) => a + b, 0);

  const totalRemoteDays = [...requestedPDSessions]
    .map(
      session =>
        ((session.overrideDate
          ? session.overrideAmount
          : session.requestedDays) || 0) *
        (session.packageSchoolBasedPDSession?.pdSession?.setting === 'REMOTE'
          ? 1
          : 0)
    )
    .reduce((a: number, b: number) => a + b, 0);

  const pdSessionCostRange = [
    packageCosts.staffDeveloperLow,
    packageCosts.staffDeveloperHigh
  ];

  const travelCosts = getTravelCost(totalDays, totalRemoteDays, packageCosts);
  return {
    totalCostRange: getTotalCostRange(pdSessionCostRange, travelCosts),
    sessionCosts: pdSessionCostRange,
    travelCosts
  };
};

interface PDSessionTotalsProps {
  pdSessionTotals: {
    totalCostRange: number[];
    sessionCosts: number[];
    travelCosts: number;
  };
}

const PDSessionTotals = ({ pdSessionTotals }: PDSessionTotalsProps) => {
  const { totalCostRange, sessionCosts, travelCosts } = pdSessionTotals;
  return (
    <>
      <div className="flex justify-between">
        <p className="sans-serif font-semibold text-gray-500">
          School-Based PD Session Total
        </p>
        <p className="sans-serif text-gray-500">
          {totalCostRange[0] === 0 && totalCostRange[1] === 0 ? (
            <span className="sans-serif">{formatAsCurrency(0)}</span>
          ) : totalCostRange[0] === totalCostRange[1] ? (
            <span className="sans-serif">
              {formatAsCurrency(totalCostRange[0])}
            </span>
          ) : (
            <span className="sans-serif">
              {formatAsCurrency(totalCostRange[0])} -{' '}
              {formatAsCurrency(totalCostRange[1])}
            </span>
          )}
        </p>
      </div>
      <div className="mt-1 ml-4">
        <div className="flex justify-between">
          <p className="sans-serif text-gray-500">Sessions Total</p>
          <span className="sans-serif text-gray-500">
            {formatAsCurrency(sessionCosts[0])} -{' '}
            {formatAsCurrency(sessionCosts[1])}
          </span>
        </div>
        <div className="flex justify-between">
          <p className="sans-serif text-gray-500">Travel Cost Total</p>
          <span className="sans-serif text-gray-500">
            {formatAsCurrency(travelCosts)}
          </span>
        </div>
      </div>
    </>
  );
};

export const getTotalEventCost = (
  requestedEvents: Partial<PackageRequestEvent[]>
) => {
  const eventTotals = requestedEvents.map(event =>
    prepareCurrencyCalculation(
      ((event?.overrideDate
        ? event.overrideAmount
        : event?.requestedParticipants) || 0) *
        (event?.packageEvent?.ticketType?.price || 0)
    )
  );
  return eventTotals.reduce((a: number, b: number) => a + b, 0);
};

interface EventSelectionTotalsProps {
  eventTotals: number;
}

const EventSelectionTotals = ({ eventTotals }: EventSelectionTotalsProps) => {
  return (
    <div className="flex justify-between border-b border-gray-300 mt-2">
      <p className="sans-serif font-semibold text-gray-500">
        Event Selection Total
      </p>
      {eventTotals > 0 ? (
        <p className="sans-serif text-gray-500">
          {formatAsCurrency(eventTotals)}
        </p>
      ) : null}
      {eventTotals === 0 ? (
        <p className="sans-serif text-gray-500">--</p>
      ) : null}
    </div>
  );
};

export const getGroupTotals = (requestedGroups: PackageRequestGroup[]) => {
  const totalPerRequestGroup: {
    total: number;
    totalPerSection: any[];
  }[] = flatPolyfill(
    // @ts-ignore
    requestedGroups?.map(g => {
      if (!g) {
        return {
          total: 0,
          totalPerSection: []
        };
      }
      const sections = g?.overrideDate
        ? g?.overrideRequestedSections || []
        : g?.requestedSections || [];

      // @ts-ignore
      const totalParticipants = sections.reduce(
        (accum: number, s: any) => (accum += s?.requestedParticipants || 0),
        0
      );
      // @ts-ignore
      let total: number = sections.reduce(
        (accum: number, s: any) =>
          (accum +=
            s.requestedParticipants *
            prepareCurrencyCalculation(g.packageGroup.attendeeCost)),
        0
      );
      // @ts-ignore
      let totalPerSection = sections.map(s => ({
        id: s?.id,
        total:
          (s?.requestedParticipants || 0) *
          prepareCurrencyCalculation(g.packageGroup.attendeeCost)
      }));
      if (
        g.packageGroup?.enableFreeSeatOffer &&
        totalParticipants > g.packageGroup?.paidSeatsRequiredForOffer
      ) {
        const diff =
          totalParticipants - g.packageGroup?.paidSeatsRequiredForOffer <
          g.packageGroup?.freeSeatsInOffer
            ? totalParticipants - g.packageGroup?.paidSeatsRequiredForOffer
            : g.packageGroup?.freeSeatsInOffer;
        total -= diff * prepareCurrencyCalculation(g.packageGroup.attendeeCost);
        let tempDiff =
          diff * prepareCurrencyCalculation(g.packageGroup.attendeeCost);
        for (const s of totalPerSection.reverse()) {
          if (tempDiff <= s.total) {
            s.total -= tempDiff;
            break;
          } else {
            tempDiff -= s.total;
            s.total = 0;
          }
        }
      }
      return { total, totalPerSection };
    }) || []
  );

  return {
    total: totalPerRequestGroup.reduce((a: any, b: any) => a + b.total, 0),
    totalPerRequestGroup
  };
};

export const getPackageRequestGroupSectionTotal = (
  packageRequestGroups: PackageRequestGroup[],
  sectionId: string
): number => {
  return getGroupTotals(
    packageRequestGroups
  ).totalPerRequestGroup[0]?.totalPerSection?.find(s => s?.id === sectionId)
    ?.total;
};

interface GroupSelectionTotalsProps {
  groupTotals: number;
}
const GroupSelectionTotals = ({ groupTotals }: GroupSelectionTotalsProps) => {
  return (
    <div className="flex justify-between border-b border-gray-300 mt-2">
      <p className="sans-serif font-semibold text-gray-500">
        Staff Developer Led Groups Total
      </p>
      {groupTotals > 0 ? (
        <p className="sans-serif text-gray-500">
          {formatAsCurrency(groupTotals)}
        </p>
      ) : null}
      {groupTotals === 0 ? (
        <p className="sans-serif text-gray-500">--</p>
      ) : null}
    </div>
  );
};

export const getInstitutesTotal = (
  requestedInstitutes: PackageRequestInstitute[]
) => {
  const instituteTotals = requestedInstitutes.map(
    i =>
      ((i.overrideDate ? i.overrideAmount : i.requestedParticipants) || 0) *
      prepareCurrencyCalculation(i.packageInstitute.ticketType?.price || 0)
  );
  return instituteTotals.reduce((a, b) => a + b, 0);
};
interface InstituteSelectionTotalsProps {
  instituteTotals: number;
}

const InstituteSelectionTotals = ({
  instituteTotals
}: InstituteSelectionTotalsProps) => {
  return (
    <div className="flex justify-between border-b border-gray-300 mt-2">
      <p className="sans-serif font-semibold text-gray-500">
        Institute Selection Total
      </p>
      {instituteTotals > 0 ? (
        <p className="sans-serif text-gray-500">
          {formatAsCurrency(instituteTotals)}
        </p>
      ) : null}
      {instituteTotals === 0 ? (
        <p className="sans-serif text-gray-500">--</p>
      ) : null}
    </div>
  );
};

const sumArray = (arr: number[]) => {
  return arr.reduce((a, b) => a + b, 0);
};

interface PackageTotalsProps {
  totals: PackageRequestTotals;
  showRedTotal?: boolean;
}

const PackageTotals = ({ totals, showRedTotal }: PackageTotalsProps) => {
  const { costRange, packageMinimum } = totals;

  const packageTotalClasses = classnames({
    'sans-serif font-bold text-gray-700 text-lg': true,
    'text-red-500': showRedTotal && costRange.low < packageMinimum / 100
  });

  return (
    <>
      <div className="flex justify-between mt-4">
        <p className={packageTotalClasses}>
          Package Total (Minimum: {formatAsCurrency(packageMinimum / 100)})
        </p>
        <p className="sans-serif text-gray-700 text-lg">
          {formatAsCurrency(costRange.low)} - {formatAsCurrency(costRange.high)}
        </p>
      </div>
      <div>
        {showRedTotal && costRange.low < packageMinimum / 100 && (
          <p className="text-red-500 italic text-sm sans-serif">
            Package minimum must be met before submitting
          </p>
        )}
      </div>
    </>
  );
};
interface PackageCostTotalsProps {
  package: Package;
  totals?: PackageRequestTotals;
  showRedTotal?: boolean;
  isLoading?: boolean;
}

export type PackageRequestTotals = {
  packageCosts: PackageCosts;
  instituteTotals: number;
  pdSessionTotals: {
    totalCostRange: number[];
    sessionCosts: number[];
    travelCosts: number;
  };
  eventTotals: number;
  groupTotals: number;
  costRange: {
    low: number;
    high: number;
  };
  packageMinimum: number;
};

const PackageCostTotals = (props: PackageCostTotalsProps) => {
  return (
    <div className="mt-4 border-gray-300 border  p-4 rounded-lg w-1/2">
      {props.isLoading && (
        <div className="py-5 my-5">
          <LoadingDialog />
        </div>
      )}
      {!props.isLoading && !props.totals && <></>}
      {!props.isLoading && !!props.totals && (
        <>
          {!!props.package?.pdSessionGroupings?.length && (
            <div className="flex justify-between border-b border-gray-300">
              <div className="w-full">
                <PDSessionTotals
                  pdSessionTotals={props.totals.pdSessionTotals}
                />
              </div>
            </div>
          )}
          {!!props.package?.events?.length && (
            <div>
              <EventSelectionTotals eventTotals={props.totals.eventTotals} />
            </div>
          )}
          {!!props.package?.groups?.length && (
            <div>
              <GroupSelectionTotals groupTotals={props.totals.groupTotals} />
            </div>
          )}
          {!!props.package?.institutes?.length && (
            <div>
              <InstituteSelectionTotals
                instituteTotals={props.totals.instituteTotals}
              />
            </div>
          )}

          <div>
            <PackageTotals
              totals={props.totals}
              showRedTotal={props.showRedTotal}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PackageCostTotals;
