import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { PackageContext } from '../../context/PackageContext';
import Card from '../common/Card';
import LoadingDialog from '../common/LoadingDialog';
import SEO from '../Seo';
import {
  fillRange,
  flatPolyfill,
  formatAsCurrency,
  getGroupSectionStaffDevelopers,
  getUniqueStaffDeveloperGroupSectionDates
} from './../../util';
import Label from './../common/Label';
import { getPackageRequestById, getPackageRequestTotals } from './api';
import PackageCostTotals, {
  PackageRequestTotals,
  getGroupTotals,
  getPackageRequestGroupSectionTotal
} from './PackageCostTotals';
import PackageSaveControls from './PackageSaveControls';
import SelectionInstructions from './SelectionInstructions';
import {
  GroupSection,
  GroupSectionStaffDeveloper,
  PackageGroup,
  PackageGroupSection,
  PackageRequest,
  PackageRequestGroup,
  PackageRequestGroupSection
} from './NewApiTypes.generated';
import {
  ManageServicesEnum,
  getManageServicesNextStep,
  getManageServicesPreviousStep
} from './Packages';

export const getPackageGroupsGroupsMinimumAmounts = (
  packageRequest: PackageRequest
) => {
  return (
    packageRequest?.package?.groups?.map(
      // @ts-ignore
      (g: PackageGroup) => {
        const packageRequestGroup = packageRequest.requestedGroups?.find(
          rg => rg?.packageGroup?.id === g.id
        );

        // TODO: Check if we need minimumAmount if it's > 0, but not required for purchase
        const amount = g.requiredForPurchase ? g.minimumAmount || 0 : 0;

        const sections = (packageRequestGroup?.overrideDate
          ? packageRequestGroup.overrideRequestedSections
          : packageRequestGroup?.requestedSections) as PackageRequestGroupSection[];

        return {
          amount,
          packageGroupId: g.id,
          name: g.group.name,
          shouldDisable:
            sections?.reduce(
              (sum, curr) =>
                (sum += curr.requestedParticipants * g.attendeeCost),
              0
            ) < amount ||
            (amount && !packageRequestGroup)
        };
      }
    ) || []
  );
};

interface GroupOptionProps {
  title: string;
  description: string;
  dates: string[];
  open: boolean;
  complete: boolean;
  participantOptions: { value: number; label: string }[];
  onParticipantSelect: (v: { value: number; label: string }) => any;
  onParticipantClear: () => any;
  participantCost: number;
  spotsRemaining: number;
  leaders: string[];
  groupSelection: PackageRequestGroup;
  sectionSelection: PackageRequestGroupSection;
  isSubmitted?: boolean;
}

const GroupOption = (props: GroupOptionProps) => {
  const buttonClasses = classnames({
    'flex justify-between w-full px-4 py-2 font-medium text-left rounded-t-lg focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 sans-serif': true,
    'bg-primary hover:bg-primary-dark text-blue-100':
      props.spotsRemaining >= 0 &&
      !props.title.toLowerCase().includes('virtual'),
    'bg-red-600 hover:bg-red-700 text-red-100': props.spotsRemaining < 0,
    'bg-purple-600 hover:bg-purple-700 text-blue-100': props.title
      .toLowerCase()
      .includes('virtual'),
    'rounded-b-lg': !props.open
  });
  const chevronClasses = classnames({
    'w-5 h-5 text-blue-100': true,
    'transform rotate-180': props.open
  });
  const spotsRemainingClasses = classnames({
    'sans-serif': true,
    'text-red-600 font-bold': props.spotsRemaining <= 5
  });

  const defaultValue = props.participantOptions.find(
    option => option.value === props.sectionSelection?.requestedParticipants
  );

  const isOverridenGroup = !!props.groupSelection?.overrideDate;

  return (
    <>
      <Disclosure.Button className={buttonClasses}>
        <span className="sans-serif text-lg">
          <FontAwesomeIcon
            icon={(defaultValue?.value || 0) > 0 ? faCheckCircle : faCircle}
            className="text-blue-100 mr-2"
          />
          {props.title}
        </span>
        <ChevronUpIcon className={chevronClasses} />
      </Disclosure.Button>
      <Disclosure.Panel className="p-6 text-gray-700 sans-serif border-gray-300 border-b border-l border-r rounded-b-lg flex">
        <>
          <div className="w-3/4 sans-serif text-lg flex">
            <div>
              <div>
                <Label text="Leaders" />
                <p className="text-gray-800 sans-serif">
                  {props.leaders.join('\n')}
                </p>
              </div>
              {props.description ? (
                <div className="mt-2">
                  <Label text="Description" />
                  <div
                    className="mt-1"
                    dangerouslySetInnerHTML={{
                      __html: props.description || ''
                    }}
                  />
                </div>
              ) : null}
              <div className="mt-2">
                <Label text="Date(s)" />
                {props.dates.length ? (
                  <ul>
                    {props.dates.map((d, i) => (
                      <li className="text-gray-800 sans-serif" key={i}>
                        {d}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className="text-gray-800 sans-serif">No dates specified</p>
                )}
              </div>
            </div>
          </div>
          <div className="w-1/4 ml-4">
            <Label text="Participants" />
            <div className="mt-2">
              <>
                {props.isSubmitted ? (
                  <span>{defaultValue?.label || '0'}</span>
                ) : (
                  <>
                    <Select
                      options={props.participantOptions}
                      onChange={v => props.onParticipantSelect(v as any)}
                      value={defaultValue || null}
                      isDisabled={isOverridenGroup}
                    />
                    {!!defaultValue && !isOverridenGroup && (
                      <button
                        type="button"
                        className="mt-1 ml-1 flex items-center gap-2"
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          props.onParticipantClear();
                        }}
                      >
                        <span className="text-red-400"> Clear</span>
                        <FontAwesomeIcon
                          icon={faTimes}
                          className="text-red-400"
                        />
                      </button>
                    )}
                  </>
                )}
              </>
              <div className="mt-4 flex justify-between">
                <p className="sans-serif font-bold">Participant Cost</p>
                <p className="sans-serif font-bold">
                  {formatAsCurrency(
                    getPackageRequestGroupSectionTotal(
                      [props.groupSelection],
                      props.sectionSelection?.id
                    )
                  )}
                </p>
              </div>
              {!props.isSubmitted && (
                <div className="mt-4 flex justify-between">
                  <p className={spotsRemainingClasses}>
                    {(props.spotsRemaining || 0) >= 0
                      ? 'Spots Remaining'
                      : 'Selections Overallocated'}
                  </p>
                  <p className={spotsRemainingClasses}>
                    {(props.spotsRemaining || 0) >= 0
                      ? props.spotsRemaining
                      : -props.spotsRemaining}
                  </p>
                </div>
              )}
            </div>
          </div>
        </>
      </Disclosure.Panel>
    </>
  );
};

interface GroupSelectionProps {
  path: string;
  packageRequestId: string;
}
const GroupSelection = (props: GroupSelectionProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [groupSelections, setGroupSelections] = useState<
    PackageRequestGroup[]
  >();

  const [groupSelectionsForDelete, setGroupSelectionsForDelete] = useState<
    PackageRequestGroup[]
  >();
  const packageContext = useContext(PackageContext);

  const { data, isLoading } = useQuery<{
    packageRequest: PackageRequest;
  }>(
    'packageRequestQuery',
    () => getPackageRequestById(props.packageRequestId),
    {
      onSuccess: data => {
        const preparedGroupSelections =
          data.packageRequest?.requestedGroups || [];
        setGroupSelections(preparedGroupSelections as PackageRequestGroup[]);
        setIsSubmitted(data.packageRequest.status === 'SUBMITTED');
      }
    }
  );

  const { data: totalsData, isLoading: isTotalsLoading } = useQuery<{
    pdfData: any;
    totals: PackageRequestTotals;
  }>(['packageRequestTotalsQuery', groupSelections], () =>
    getPackageRequestTotals(props.packageRequestId, {
      requestedGroups: groupSelections
    })
  );

  const spotsRemaining =
    data?.packageRequest?.package?.groups?.map(
      // @ts-ignore
      (g: PackageGroup) => {
        const spotsRemaining =
          g.maxAttendees -
          ((groupSelections?.find(
            gs => gs.packageGroupId === g.id
          ) as PackageRequestGroup)?.requestedSections?.reduce(
            (sum, curr) => (sum += curr?.requestedParticipants || 0),
            0
          ) || 0);
        return spotsRemaining || 0;
      }
    ) || [];

  const requirePurchase = getPackageGroupsGroupsMinimumAmounts({
    ...(data?.packageRequest || {}),
    requestedGroups: groupSelections as PackageRequestGroup[]
  } as PackageRequest);

  return (
    <>
      <SEO title="Group Selection" />
      {isLoading ? <LoadingDialog /> : null}
      {data && data.packageRequest.package && (
        <>
          <Card>
            <h2 className="sans-serif text-primary font-bold text-2xl border-b border-gray-300">
              {data.packageRequest.organization.name} -{' '}
              {data.packageRequest.package.name}
            </h2>
            <div
              className="mt-4"
              dangerouslySetInnerHTML={{
                __html: data.packageRequest.package.groupsDescription || ''
              }}
            />
          </Card>

          <div className="mt-4">
            {data.packageRequest.package.groups.map(
              // @ts-ignore
              (g: PackageGroup, idx) => (
                <Card key={g.group.id} className="mt-4">
                  <div className="mb-10">
                    <p className="sans-serif text-primary font-bold text-2xl border-b border-gray-300">
                      {g.group.name}
                    </p>
                    <div
                      className="mt-4"
                      dangerouslySetInnerHTML={{
                        __html: g.description || ''
                      }}
                    />
                    <div className="w-1/2 m-auto mt-6">
                      <SelectionInstructions isSubmitted={isSubmitted} />
                    </div>
                    <div className="mt-4">
                      <div className="flex mt-2">
                        <div className="mr-10">
                          <Label text="Price Per Participant" />
                          <p className="sans-serif text-lg">
                            {formatAsCurrency(g.attendeeCost / 100)}
                          </p>
                        </div>
                        <div className="mr-10">
                          <Label text="Current Subtotal for Group" />
                          <p className="sans-serif text-lg">
                            {formatAsCurrency(
                              getGroupTotals([
                                groupSelections?.find(
                                  gs => gs.packageGroupId === g.id
                                ) as PackageRequestGroup
                              ] as PackageRequestGroup[])?.total
                            )}
                          </p>
                        </div>
                        <div className="mr-10">
                          <Label text="Number of Participants" />
                          <p className="sans-serif text-lg">
                            Minimum: {g.minAttendees}
                          </p>
                          <p className="sans-serif text-lg">
                            Maximum: {g.maxAttendees}
                          </p>
                        </div>
                        {!!requirePurchase[idx]?.shouldDisable && (
                          <div className="mr-10">
                            <p className="sans-serif text-md text-yellow-600">
                              Required minimum amount -
                              {formatAsCurrency(
                                requirePurchase[idx].amount / 100
                              )}
                            </p>
                          </div>
                        )}
                      </div>
                      {!!(groupSelections?.find(
                        gs => gs.packageGroupId === g.id
                      ) as PackageRequestGroup)?.overrideDate && (
                        <div className="ml-auto mr-3 text-yellow-600">{`This Group has been overriden by admin, and can't be changed`}</div>
                      )}
                      {g.group?.groupSections?.map(
                        // @ts-ignore
                        (s: GroupSection) => (
                          <div className="w-full mt-4" key={s.id}>
                            <Disclosure>
                              {({ open }) => {
                                return (
                                  <GroupOption
                                    title={s.title}
                                    description={s.description || ''}
                                    groupSelection={
                                      groupSelections?.find(
                                        gs => gs.packageGroupId === g.id
                                      ) as PackageRequestGroup
                                    }
                                    isSubmitted={isSubmitted}
                                    sectionSelection={(() => {
                                      const grSel = groupSelections?.find(
                                        gs => gs.packageGroupId === g.id
                                      ) as PackageRequestGroup;
                                      let sections = grSel?.requestedSections;
                                      if (grSel?.overrideDate) {
                                        sections = grSel.overrideRequestedSections as PackageRequestGroupSection[];
                                      }

                                      return sections?.find(
                                        rs =>
                                          rs?.packageGroupSection?.groupSection
                                            ?.id === s.id
                                      ) as PackageRequestGroupSection;
                                    })()}
                                    participantCost={g.attendeeCost}
                                    spotsRemaining={spotsRemaining[idx]}
                                    dates={getUniqueStaffDeveloperGroupSectionDates(
                                      s.groupSectionStaffDevelopers as GroupSectionStaffDeveloper[]
                                    )}
                                    leaders={getGroupSectionStaffDevelopers(
                                      s.groupSectionStaffDevelopers as GroupSectionStaffDeveloper[]
                                    )}
                                    participantOptions={fillRange(
                                      s.minTickets,
                                      s.maxTickets
                                    ).map(v => ({
                                      value: v,
                                      label: v.toString()
                                    }))}
                                    onParticipantClear={() => {
                                      packageContext.setHasUnsavedChanges(true);

                                      const preparedGroupForDelete: Partial<PackageRequestGroup> = groupSelectionsForDelete?.find(
                                        gs => gs.packageGroupId === g.id
                                      ) || {
                                        packageGroup: g,
                                        packageGroupId: g.id,
                                        requestedSections: []
                                      };

                                      const preparedSectionForDelete: Partial<PackageRequestGroupSection> =
                                        groupSelectionsForDelete
                                          ?.find(
                                            gs => gs.packageGroupId === g.id
                                          )
                                          ?.requestedSections?.find(
                                            rs =>
                                              rs?.packageGroupSection
                                                ?.groupSection?.id === s.id
                                          ) ||
                                        (({
                                          packageGroupSection: {
                                            groupSection: s,
                                            groupSectionId: s.id
                                          } as PackageGroupSection
                                        } as unknown) as Partial<
                                          PackageRequestGroupSection
                                        >);

                                      preparedGroupForDelete.requestedSections = ([
                                        ...(preparedGroupForDelete?.requestedSections?.filter(
                                          rs =>
                                            rs?.packageGroupSection
                                              ?.groupSectionId !==
                                            preparedSectionForDelete
                                              .packageGroupSection
                                              ?.groupSectionId
                                        ) || []),
                                        preparedSectionForDelete
                                      ] as unknown) as PackageRequestGroupSection[];

                                      setGroupSelectionsForDelete([
                                        ...((groupSelectionsForDelete?.filter(
                                          gs =>
                                            gs.packageGroupId !==
                                            preparedGroupForDelete.packageGroupId
                                        ) as PackageRequestGroup[]) || []),
                                        preparedGroupForDelete as PackageRequestGroup
                                      ]);

                                      // remove data from seletion
                                      const preparedGroup:
                                        | Partial<PackageRequestGroup>
                                        | undefined = groupSelections?.find(
                                        gs => gs.packageGroupId === g.id
                                      );

                                      if (preparedGroup) {
                                        const preparedSection:
                                          | Partial<PackageRequestGroupSection>
                                          | undefined
                                          | null = groupSelections
                                          ?.find(
                                            gs => gs.packageGroupId === g.id
                                          )
                                          ?.requestedSections?.find(
                                            rs =>
                                              rs?.packageGroupSection
                                                ?.groupSection?.id === s.id
                                          );

                                        if (preparedSection) {
                                          preparedGroup.requestedSections = ([
                                            ...(preparedGroup?.requestedSections?.filter(
                                              rs =>
                                                rs?.packageGroupSection
                                                  ?.groupSectionId !==
                                                preparedSection
                                                  .packageGroupSection
                                                  ?.groupSectionId
                                            ) || [])
                                          ] as unknown) as PackageRequestGroupSection[];

                                          setGroupSelections([
                                            ...((groupSelections || [])?.filter(
                                              gs =>
                                                gs.packageGroupId !==
                                                preparedGroup.packageGroupId
                                            ) as PackageRequestGroup[]),
                                            preparedGroup as PackageRequestGroup
                                          ]);
                                        }
                                      }
                                    }}
                                    onParticipantSelect={({ value }) => {
                                      packageContext.setHasUnsavedChanges(true);

                                      const preparedGroup: Partial<PackageRequestGroup> = groupSelections?.find(
                                        gs => gs.packageGroupId === g.id
                                      ) || {
                                        packageGroup: g,
                                        packageGroupId: g.id,
                                        requestedSections: []
                                      };

                                      const preparedSection: Partial<PackageRequestGroupSection> =
                                        groupSelections
                                          ?.find(
                                            gs => gs.packageGroupId === g.id
                                          )
                                          ?.requestedSections?.find(
                                            rs =>
                                              rs?.packageGroupSection
                                                ?.groupSection?.id === s.id
                                          ) ||
                                        (({
                                          packageGroupSection: {
                                            groupSection: s,
                                            groupSectionId: s.id
                                          } as PackageGroupSection
                                        } as unknown) as Partial<
                                          PackageRequestGroupSection
                                        >);

                                      preparedSection.requestedParticipants = value;

                                      preparedGroup.requestedSections = ([
                                        ...(preparedGroup?.requestedSections?.filter(
                                          rs =>
                                            rs?.packageGroupSection
                                              ?.groupSectionId !==
                                            preparedSection.packageGroupSection
                                              ?.groupSectionId
                                        ) || []),
                                        preparedSection
                                      ] as unknown) as PackageRequestGroupSection[];

                                      setGroupSelections([
                                        ...(groupSelections?.filter(
                                          gs =>
                                            gs.packageGroupId !==
                                            preparedGroup.packageGroupId
                                        ) as PackageRequestGroup[]),
                                        preparedGroup as PackageRequestGroup
                                      ]);

                                      // remove from delete data
                                      const preparedGroupForDelete:
                                        | Partial<PackageRequestGroup>
                                        | undefined = groupSelectionsForDelete?.find(
                                        gs => gs.packageGroupId === g.id
                                      );

                                      if (preparedGroupForDelete) {
                                        const preparedSectionForDelete:
                                          | Partial<PackageRequestGroupSection>
                                          | undefined
                                          | null = groupSelectionsForDelete
                                          ?.find(
                                            gs => gs.packageGroupId === g.id
                                          )
                                          ?.requestedSections?.find(
                                            rs =>
                                              rs?.packageGroupSection
                                                ?.groupSection?.id === s.id
                                          );

                                        if (preparedSectionForDelete) {
                                          preparedGroupForDelete.requestedSections = ([
                                            ...(preparedGroupForDelete?.requestedSections?.filter(
                                              rs =>
                                                rs?.packageGroupSection
                                                  ?.groupSectionId !==
                                                preparedSectionForDelete
                                                  .packageGroupSection
                                                  ?.groupSectionId
                                            ) || [])
                                          ] as unknown) as PackageRequestGroupSection[];

                                          setGroupSelectionsForDelete([
                                            ...((
                                              groupSelectionsForDelete || []
                                            )?.filter(
                                              gs =>
                                                gs.packageGroupId !==
                                                preparedGroup.packageGroupId
                                            ) as PackageRequestGroup[]),
                                            preparedGroupForDelete as PackageRequestGroup
                                          ]);
                                        }
                                      }
                                    }}
                                    open={open}
                                    complete={false}
                                  />
                                );
                              }}
                            </Disclosure>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </Card>
              )
            )}

            <Card className="mt-4">
              <div className="flex justify-between">
                <PackageCostTotals
                  isLoading={isTotalsLoading}
                  package={data.packageRequest.package}
                  totals={totalsData?.totals}
                />
                <div>
                  {requirePurchase.some(r => r.shouldDisable) && (
                    <div className="text-yellow-600 mx-4 mb-4">
                      There are several groups that require a minimum value that
                      you have not reached:
                      <ul className="list-disc ml-4">
                        {requirePurchase
                          .filter(r => r.shouldDisable)
                          .map(r => (
                            <li key={r.packageGroupId}>
                              {r.name} - {formatAsCurrency(r.amount / 100)}
                            </li>
                          ))}
                      </ul>
                    </div>
                  )}
                  <PackageSaveControls
                    isDisabled={
                      spotsRemaining.some(v => v < 0) ||
                      requirePurchase.some(r => r.shouldDisable)
                    }
                    packageRequest={data.packageRequest}
                    segment="GROUPS"
                    segmentData={groupSelections}
                    deleteData={groupSelectionsForDelete}
                    nextRoute={getManageServicesNextStep(
                      ManageServicesEnum.GROUP_SELECTION,
                      data.packageRequest
                    )}
                    previousRoute={getManageServicesPreviousStep(
                      ManageServicesEnum.GROUP_SELECTION,
                      data.packageRequest
                    )}
                  />
                </div>
              </div>
            </Card>
          </div>
        </>
      )}
      <></>
    </>
  );
};

export default GroupSelection;
