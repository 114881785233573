import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { navigate } from 'gatsby';
import React, { useContext, useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import Button from '../common/Button';
import Card from '../common/Card';
import SEO from '../Seo';
import { formatPhoneNumber } from './../../util';
import Affiliations from './Affiliations/Affiliations';
import EventApplications from './EventApplication/EventApplications';
import ProfileEdit from './ProfileEdit';
import FormLabel from './FormLabel';
import MembersPageTitle from './MembersPageTitle';
import ProfilePhoto from './ProfilePhoto';
import { logoutUser, updateEmail, userExists } from './Queries';
import { User } from './User';
import { applyToEvent, updateProfile } from './api';

const getUserAddress = (user: User) => {
  if (!user) {
    return [];
  }
  let userAddress;
  const { city, stateProvince, zipPostal } = user;
  if (!city && !stateProvince && !zipPostal) {
    userAddress = '';
  } else if (!city && stateProvince && zipPostal) {
    userAddress = `${stateProvince} ${zipPostal}`;
  } else if (city && stateProvince && zipPostal) {
    userAddress = `${city}, ${stateProvince} ${zipPostal}`;
  }

  return [
    user.address1 || '',
    user.address2 || '',
    userAddress,
    user.country || ''
  ];
};

interface InfoBlockProps {
  title: string;
  info: string[];
}

const InfoBlock = (props: InfoBlockProps) => {
  const filteredInfo = props.info.filter((item: string) => !!item);
  return (
    <div>
      <label className="uppercase text-gray-500 text-sm sans-serif">
        {props.title}
      </label>
      <div className="mt-1">
        {!filteredInfo.length ? (
          <p className="text-gray-400 sans-serif italic">Not set</p>
        ) : (
          <>
            {filteredInfo.map((item: string, i: number) => (
              <p className="text-gray-700 sans-serif" key={i}>
                {item}
              </p>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

interface ProfileStaticProps {
  user: User;
  setIsEditing: any;
  profilePhoto: any;
}

const ProfileStatic = (props: ProfileStaticProps) => {
  const logout = async () => {
    try {
      await logoutUser();

      if (auth.setAuthState) {
        auth.setAuthState(null);
        navigate('/auth/login');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const auth = useContext(AuthContext);
  const userInfo = auth.authState;

  const [emailEditing, setEmailEditing] = useState(false);
  const [newEmail, setNewEmail] = useState(props.user.email);
  const [newEmailError, setNewEmailError] = useState('');

  const mutation = useMutation(
    async (data: any) =>
      updateProfile({ email: data.email.trim().toLowerCase() }),
    {
      onSettled: data => {
        console.log('data---: ', data);
        if (data && data.state === 'SUCCESS') {
          toast.success(
            'Email updated. You must now log in again with your new email.'
          );
          setEmailEditing(false);
          setTimeout(() => {
            logout();
          }, 4000);
        } else {
          const errorMessage = 'The specified email is already taken';
          setNewEmailError(errorMessage);
          throw new Error(errorMessage);
        }
      }
    }
  );
  const changeEmail = (email: string) => {
    setNewEmailError('');
    if (!validateEmail(email)) {
      const errorMessage = 'The specified email address is invalid.';
      return setNewEmailError(errorMessage);
    } else if (props.user.email === email) {
      const errorMessage = 'You are already using the specified email';
      return setNewEmailError(errorMessage);
    } else {
      mutation.mutate({ email: newEmail });
    }
  };

  // TODO - delete this after testing

  const [event, setEvent] = useState('' as string);

  const applyToEventClick = async () => {
    if (!event) {
      return toast.error('Please enter an event ID');
    }
    const result = await applyToEvent(event);

    if (result.state === 'ERROR') {
      toast.error('There was an issue applying to this event');
    } else {
      navigate(
        `/members/events/applications/${result.data.id}/manage/general-information`
      );
    }
  };
  return (
    <Card>
      <div className="flex border-b border-gray-200 pb-8">
        <div className="w-24">
          <ProfilePhoto photo={props.profilePhoto} />
        </div>
        <div className="ml-4 m-auto">
          <p className="sans-serif text-primary font-bold text-3xl">
            {props.user.firstName} {props.user.lastName}
          </p>
        </div>

        {/* TODO - delete this after testing */}
        <div className="my-auto flex items-end">
          {/* <div className="mr-4 flex items-end">
            <input
              type="text"
              placeholder="Enter event ID"
              className="bg-gray-200 w-full rounded-full px-4 py-3 focus:outline-none p-4 sans-serif mr-2"
              onChange={e => setEvent(e.target.value)}
            />
            <div className="w-64">
              <Button text="Apply to Event" onClick={applyToEventClick} />
            </div>
          </div> */}
          <Button
            text="Edit Profile"
            icon={faEdit}
            onClick={props.setIsEditing}
          />
        </div>
      </div>
      <div className="mt-8 flex">
        <div className="mr-8 flex w-full">
          <div className="mr-8">
            <InfoBlock title="Address" info={getUserAddress(props.user)} />
          </div>
          <div className="mr-8">
            <InfoBlock
              title="Phone"
              info={[formatPhoneNumber(props.user.phone)]}
            />
          </div>
          <div className="flex flex-col w-96">
            <EmailEdit
              email={newEmail}
              isEditing={emailEditing}
              onChange={(value: string) => setNewEmail(value)}
            />
            {newEmailError ? (
              <div className="mt-2">
                <p className="text-red-500 text-sm sans-serif">
                  {newEmailError}
                </p>
              </div>
            ) : null}

            <div>
              {emailEditing ? (
                <div className="mt-2">
                  <Button
                    type="button"
                    size="sm"
                    text="Save Email"
                    onClick={() => changeEmail(newEmail)}
                  />
                  <button
                    type="button"
                    className="text-red-500 text-sm hover:underline ml-4 sans-serif mt-2"
                    onClick={() => {
                      setNewEmail(props.user.email);
                      setNewEmailError('');
                      setEmailEditing(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              ) : (
                <button
                  type="button"
                  className="text-sm text-primary sans-serif hover:underline"
                  onClick={() => setEmailEditing(true)}
                >
                  (update email)
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

enum PROFILE_EDIT_FORM {
  IDLE,
  SUBMITTING,
  ERROR,
  COMPLETE
}

interface EmailEditProps {
  email: string;
  isEditing: boolean;
  onChange: (value: string) => string;
}

const EmailEdit = (props: EmailEditProps) => {
  return (
    <>
      {props.isEditing ? (
        <>
          <FormLabel label="Email" />
          <input
            autoFocus
            className="border-gray-200 border p-2 sans-serif rounded-md bg-gray-100"
            placeholder="Email"
            defaultValue={props.email}
            onChange={e => props.onChange(e.target.value)}
          />
        </>
      ) : (
        <>
          <FormLabel label="Email" />
          <p className="sans-serif text-gray-600">{props.email}</p>
        </>
      )}
    </>
  );
};

function validateEmail(email: string) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

interface ProfileProps {
  path: string;
  user: User;
}
const Profile = (props: ProfileProps) => {
  const auth = useContext(AuthContext);
  const profilePhoto = require(`./../../images/user_placeholder.png`);
  const [isEditing, setIsEditing] = useState(false);
  const [formState, setFormState] = useState(PROFILE_EDIT_FORM.IDLE);
  const mutation = useMutation(data => updateProfile(data), {
    onMutate: variables => {
      setFormState(PROFILE_EDIT_FORM.SUBMITTING);
    },
    onSettled: data => {
      if (data) {
        toast.success('Profile updated');
        auth.setAuthState({ ...data?.user });
        setFormState(PROFILE_EDIT_FORM.COMPLETE);
        setIsEditing(false);
      } else {
        toast.error('There was a problem updating your profile');
        setFormState(PROFILE_EDIT_FORM.COMPLETE);
      }
    }
  });
  return (
    <div>
      <SEO title="Profile" />
      <div className="mb-4">
        <MembersPageTitle title="Profile" />
      </div>
      {isEditing ? (
        <ProfileEdit
          user={props.user}
          setIsEditing={() => setIsEditing(false)}
          profilePhoto={profilePhoto}
          onSubmit={data => mutation.mutate(data)}
          formState={formState}
        />
      ) : (
        <ProfileStatic
          user={props.user}
          setIsEditing={() => setIsEditing(true)}
          profilePhoto={profilePhoto}
        />
      )}
      <div className="mt-4">
        <Affiliations authState={auth.authState} />
      </div>
      <div className="mt-4">
        <EventApplications />
      </div>
    </div>
  );
};

export default Profile;
