import React from 'react';
import {
  faFile,
  faFilePdf,
  faFileVideo
} from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useMutation } from 'react-query';
import { UserInfo } from '../../context/User';
import { getConnectLink } from './../../util';
import { User } from './NewApiTypes.generated';

type ResourceItem = {
  id: string;
  title: string;
  type: 'pdf' | 'video';
  downloadLink?: string;
  collection?: string;
  isFavorited?: boolean;
  toolkitGoogleDriveId?: string;
};
interface ExternalResourceLinkProps {
  item: ResourceItem;
  collectionSlug: string;
  user: UserInfo | User;
}

const ExternalResourceLink = (props: ExternalResourceLinkProps) => {
  let link;

  if (props.item.type === 'pdf') {
    link = getConnectLink(props.item.downloadLink || '', props.user as User);
  } else if (props.item.type === 'video') {
    link = `video-collection/${props.collectionSlug}?selectedVideo=${props.item.id}`;
  }

  return (
    <a
      href={link}
      target="_blank"
      className="flex justify-between hover:underline"
    >
      <div>
        <FontAwesomeIcon
          icon={
            props.item.type == 'pdf'
              ? faFilePdf
              : props.item.type === 'video'
              ? faFileVideo
              : faFile
          }
        />
        <span className="sans-serif ml-4">{props.item.title}</span>
      </div>
    </a>
  );
};

async function favoriteResource(faveItem: any, userId: string) {
  const req = await fetch(`${process.env.GATSBY_FUNCTIONS_PATH}/favorites`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userId,
      docId: faveItem.id
    })
  });
  return await req.json();
}

async function unfavoriteResource(faveItem: any, userId: string) {
  const req = await fetch(`${process.env.GATSBY_FUNCTIONS_PATH}/favorites`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      userId,
      docId: faveItem.id
    })
  });
  return await req.json();
}

interface ResourceItemProps {
  item: ResourceItem;
  collectionSlug: string;
  onClick?: any;
  onFavorite?: any;
  user: UserInfo | User;
}
const ResourceItem = (props: ResourceItemProps) => {
  const mutation = useMutation(async ({ faveItem, isFavorited }: any) => {
    try {
      const userId = String(
        (props.user as any)?.portalMetadata?.userId || props.user.id
      );
      if (isFavorited) {
        return await unfavoriteResource(faveItem, userId);
      } else {
        return await favoriteResource(faveItem, userId);
      }
    } catch (err) {
      console.log(err);
    }
  });
  return (
    <li
      className="text-primary cursor-pointer font-semibold text-lg p-4 border-b border-gray-200"
      onClick={props.onClick}
    >
      <div>
        <div className="flex justify-between">
          <ExternalResourceLink
            item={props.item}
            collectionSlug={props.collectionSlug}
            user={props.user}
          />
          <button
            className="my-auto"
            onClick={() => {
              mutation.mutate({
                faveItem: props.item,
                isFavorited: props.item.isFavorited
              });
              props.item.isFavorited = !props.item.isFavorited;
            }}
          >
            <FontAwesomeIcon
              icon={faStar}
              className={classnames({
                'text-gray-300 hover:text-yellow-400': true,
                'text-yellow-400': props.item.isFavorited
              })}
            />
          </button>
        </div>
      </div>
      {props.item.collection ? (
        <div className="mt-2">
          <p className="sans-serif text-gray-500 text-sm font-normal">
            {props.item.collection}
          </p>
        </div>
      ) : null}
    </li>
  );
};

export default ResourceItem;
