import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import Card from '../../common/Card';
import { FormWrapper, FormField } from '../../common/Form';
import LoadingDialog from '../../common/LoadingDialog';
import { getEventApplication, updateEventApplication } from './../api';
import ApplicationSaveControls, {
  ApplicationSaveTypeEnum
} from './../ApplicationSaveControls';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { navigate } from 'gatsby';
import EventApplicationHeader from './EventApplicationHeader';
import { EventManagementContext } from '../EventManagement';

export enum QuestionType {
  RADIO = 'RADIO',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  CUSTOM = 'CUSTOM',
  INPUT = 'INPUT'
}
interface EventApplicationQuestionnaireProps {
  path: string;
  children: React.ReactChildren;
  applicationId: string;
}

export const parseEventApplicationQuestions = (questions: any[], data: any) => {
  return questions.reduce((accum, q) => {
    const answer = data.application.questionnaire?.length
      ? data.application.questionnaire.find(
          (question: any) => question.eventApplicationQuestionId === q.id
        )
      : null;
    if (q.question.type === QuestionType.CHECKBOX) {
      accum[q.id] =
        answer?.value ||
        q.question.options.reduce(
          (acc: { [key: string]: boolean }, o: string) => {
            acc[o] = false;
            return acc;
          },
          {}
        );
    } else {
      accum[q.id] = answer?.value || '';
    }
    return accum;
  }, {});
};

const EventApplicationQuestionnaire = (
  props: EventApplicationQuestionnaireProps
) => {
  const { setIsAllRequiredQuestionsAnswered } = useContext(
    EventManagementContext
  );
  const [eventApplicationQuestions, setEventApplicationQuestions] = useState<
    any[]
  >([]);
  const [validationSchema, setValidationSchema] = useState<any>(Yup.object());
  const [submitting, setSubmitting] = useState<ApplicationSaveTypeEnum | null>(
    null
  );

  const { data, isLoading, error } = useQuery(
    ['eventApplication'],
    () => getEventApplication(props.applicationId),
    {
      onSuccess: data => {
        setEventApplicationQuestions(
          data.application.event.applicationQuestions
        );
      }
    }
  );
  const formik = useFormik<any>({
    initialValues: {},
    validationSchema,
    onSubmit: () => {}
  });

  useEffect(() => {
    if (validationSchema) {
      formik.validateForm();
    }
  }, validationSchema);

  useEffect(() => {
    if (eventApplicationQuestions?.length) {
      const values = parseEventApplicationQuestions(
        eventApplicationQuestions,
        data
      );

      setIsAllRequiredQuestionsAnswered(
        Object.entries(values).every(([key, value]) => {
          const isRequired = !!eventApplicationQuestions.find(
            eaq => eaq.id === key
          )?.question?.required;
          if (!isRequired) {
            return true;
          }
          return typeof value === 'string' ? !!value?.trim?.() : !!value;
        })
      );

      formik.setValues(values);
      setValidationSchema(
        Yup.object().shape(
          eventApplicationQuestions.reduce((accum, q) => {
            let schema;
            if (q.question.required) {
              if (q.question.type === QuestionType.CHECKBOX) {
                schema = Yup.object().test(
                  'checkbox-required',
                  'Question is required',
                  (value: any) => {
                    return Object.values(value).some(v => v);
                  }
                );
              } else {
                schema = Yup.string().required('Question is required');
              }
            }
            accum[q.id] = schema;
            return accum;
          }, {})
        )
      );
    }
  }, [eventApplicationQuestions]);

  const mutation = useMutation(
    ['saveGeneralInformation'],
    updateEventApplication
  );

  const save = async (shouldNavigate = false) => {
    const _values = formik.values;
    Object.keys(_values).forEach(k => {
      _values[k] =
        typeof _values[k] === 'string' ? _values[k]?.trim?.() : _values[k];
    });
    const values = { ..._values };

    await formik.setValues(values);
    const validation = await formik.validateForm(values);

    if (Object.keys(validation)?.length) {
      return;
    }

    const questionnaire = Object.keys(values).map(id => {
      const eventApplicationQuestion = eventApplicationQuestions.find(
        eaq => eaq.id === id
      );
      return {
        eventApplicationQuestionId: eventApplicationQuestion.id,
        applicationQuestionId: eventApplicationQuestion.question.id,
        type: eventApplicationQuestion.question.type,
        label: eventApplicationQuestion.question.label,
        value: values[id]
      };
    });
    setSubmitting(
      shouldNavigate
        ? ApplicationSaveTypeEnum.SAVE_AND_CONTINUE
        : ApplicationSaveTypeEnum.SAVE
    );
    await mutation.mutate(
      {
        segment: 'QUESTIONNAIRE',
        applicationId: props.applicationId,
        data: {
          questionnaire
        }
      },
      {
        onSuccess: data => {
          if (data.state === 'BAD_REQUEST') {
            toast.error(data.message || 'Something went wrong');
          } else {
            toast.success('Your application has been saved');
            const isAllRequiredQuestionsAnswered = Object.entries(values).every(
              ([key, value]) => {
                const isRequired = !!eventApplicationQuestions.find(
                  eaq => eaq.id === key
                )?.question?.required;
                if (!isRequired) {
                  return true;
                }
                return typeof value === 'string' ? !!value?.trim?.() : !!value;
              }
            );
            setIsAllRequiredQuestionsAnswered(isAllRequiredQuestionsAnswered);
            if (shouldNavigate && isAllRequiredQuestionsAnswered) {
              navigate(`../review-and-submit`);
            }
          }
          setSubmitting(null);
        }
      }
    );
  };

  const getInput = (eaq: any) => {
    switch (eaq.question.type) {
      case QuestionType.RADIO:
        return (
          <FormField
            name={eaq.id as string}
            type="radio"
            key={formik.values[eaq.id]}
            label={eaq.question.label}
            value={formik.values[eaq.id]}
            onChange={value => {
              formik.setFieldValue(eaq.id, value);
            }}
            options={
              eaq.question.options
                ? eaq.question.options.map((option: string) => ({
                    value: option,
                    label: option
                  }))
                : [
                    {
                      label: 'Yes',
                      value: 'Yes'
                    },
                    {
                      label: 'No',
                      value: 'No'
                    }
                  ]
            }
          ></FormField>
        );
      case QuestionType.CHECKBOX:
        return (
          <>
            <div
              className={`sans-serif text-gray-500 uppercase mb-1 text-sm ${formik!
                .touched[eaq.id] &&
                formik!.errors[eaq.id] &&
                'text-red-500'}`}
            >
              {eaq.question.label}
            </div>
            {eaq.question.options.map((option: string, index: number) => (
              <FormField
                key={index}
                name={`${eaq.id}.${option}`}
                type="checkbox"
                label={option}
              ></FormField>
            ))}
            {formik!.touched[eaq.id] && formik!.errors[eaq.id] && (
              <span className="text-red-500 text-sm sans-serif mt-1">
                {formik!.errors[eaq.id]}
              </span>
            )}
          </>
        );
      case QuestionType.SELECT:
        return (
          <FormField
            name={eaq.id as string}
            type="select"
            placeholder="Select option"
            label={eaq.question.label}
            options={eaq.question.options.map((option: string) => ({
              label: option,
              value: option
            }))}
          ></FormField>
        );
      case QuestionType.CUSTOM:
      case QuestionType.INPUT:
        return (
          <FormField
            type="textarea"
            rows={4}
            name={eaq.id as string}
            label={eaq.question.label}
            placeholder="Provide an answer"
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Card>
        <EventApplicationHeader title="Questionnaire" />
        {isLoading ? <LoadingDialog /> : null}
        {data && data.application ? (
          <FormWrapper
            formik={formik}
            hideActionButtons={true}
            isLoading={!!submitting}
          >
            {data.application.event.applicationQuestions.map(
              (applicationQuestion: any) => (
                <div key={applicationQuestion.id}>
                  <div className="mb-4">{getInput(applicationQuestion)}</div>
                </div>
              )
            )}
            <ApplicationSaveControls
              loadingType={submitting}
              isDisabled={!formik.isValid}
              saveApplication={() => save()}
              saveApplicationAndContinue={() => save(true)}
            />
          </FormWrapper>
        ) : null}
      </Card>
    </>
  );
};

export default EventApplicationQuestionnaire;
