import { navigate } from 'gatsby';
import React from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Button from '../../common/Button';
import Card from '../../common/Card';
import LoadingDialog from '../../common/LoadingDialog';
import ApplicationStatus, {
  PackageAndApplicationStatus
} from '../ApplicationStatus';
import {
  cancelEventPayment,
  eventApplicationPurchase,
  getConferenceDaysEvents,
  getEventApplications,
  getEventTicketHolders,
  getLegacyEventApplications,
  getLegacyEventPaymentLink
} from './../api';
import ApplicationPaymentStatus, {
  EventPaymentStatus
} from './ApplicationPaymentStatus';
import EventApplicationEventPaymentModal from './EventApplicationPaymentMethodModal';
import { useFetch } from '../../../hooks/useFetch';
import SEO from '../../Seo';
import { formatDates } from '../../../util/date';

const makePayment = async (eventApplicationId: number) => {
  try {
    const { paymentLink } = await getLegacyEventPaymentLink(
      eventApplicationId.toString()
    );

    if (paymentLink) {
      window.open(paymentLink);
    }
  } catch (err) {}
};

type EventApplication = {
  id: string;
  status: PackageAndApplicationStatus;
  event: {
    id: string;
    name: string;
    dates: string[];
  };
  paymentOption: {
    id: string;
    label: string;
  };
  ticketRequest: {
    id: string;
    payment: {
      id: string;
      status: string;
      purchaseOrders: [
        {
          id: string;
          status: string;
        }
      ];
    };
  };
};

const getPaymentOption = (label: string) => {
  switch (label) {
    case 'Credit Card':
      return 'Pay by Credit Card';
    case 'Purchase Order':
      return 'Pay by Purchase Order';
    default:
      return 'Pay';
  }
};

interface EventApplicationProps {
  path: string;
}
const EventApplications = (props: EventApplicationProps) => {
  const [applicationToEdit, setApplicationToEdit] = React.useState<any>(null);
  const {
    isFetching: isEventApplicationsFetching,
    error: eventApplicationsError,
    data: eventApplications,
    refetch: refetchEventApplications
  } = useQuery('eventApplications', () => getEventApplications());

  const {
    isFetching: isLegacyEventsFetching,
    error: legacyEventsError,
    data: legacyEvents
  } = useQuery('legacyEventApplications', () => getLegacyEventApplications());

  const {
    isFetching: isConferenceDaysEventsFetching,
    error: conferenceDaysEventsError,
    data: eventSectionEvents,
    refetch: refetchConferenceDaysEvents
  } = useQuery('eventSectionEvents', () => getConferenceDaysEvents());

  const {
    isFetching: isEventTicketHolderFetching,
    error: eventTicketHolderError,
    data: eventTicketHolders,
    refetch: refetchEventTicketHolders
  } = useQuery('eventTicketHolder', () => getEventTicketHolders());

  const [
    eventApplicationPurchaseIsLoading,
    setEventApplicationPurchaseIsLoading
  ] = React.useState<string | null>('');

  const { apply: applyCancelEventPayment, state } = useFetch(
    cancelEventPayment
  );

  const proceedWithPayment = async (eventApplicationId: string) => {
    setEventApplicationPurchaseIsLoading(eventApplicationId);
    try {
      const result = await eventApplicationPurchase({
        id: eventApplicationId
      });
      setEventApplicationPurchaseIsLoading(null);
      if (result.state === 'ERROR') {
        return toast.error(result.message || 'Something went wrong');
      } else {
        window.location.href = result.url;
      }
    } catch (err) {
      toast.error('Something went wrong');
      setEventApplicationPurchaseIsLoading(null);
    }
  };

  const getPaymentStatus = (
    application: EventApplication
  ): EventPaymentStatus => {
    console.log('application: ', application);
    const status = application?.ticketRequest?.payment?.status;
    if (!status) {
      return application?.paymentOption?.label === 'Purchase Order'
        ? EventPaymentStatus.AWAITING_PO
        : EventPaymentStatus.PENDING;
    }

    return status as EventPaymentStatus;
  };

  const handleCancelEventPayment = async (eventPaymentId: string) => {
    try {
      const res = await applyCancelEventPayment(eventPaymentId);
      if (res?.state === 'ERROR') {
        throw new Error(
          res?.data?.message || res?.error || 'Something went wrong'
        );
      }
      toast.success('Ticket request cancelled successfully');
      await refetchConferenceDaysEvents();
    } catch (err) {
      toast.error((err as any)?.message || 'Something went wrong');
    }
  };

  return (
    <>
      <SEO title="Event Applications" />
      <Card>
        <h3 className="text-primary font-bold text-2xl mb-6">
          My Event Applications
        </h3>
        <EventApplicationEventPaymentModal
          onClose={() => {
            setApplicationToEdit(null);
          }}
          onSubmit={() => {
            setApplicationToEdit(null);
            refetchEventApplications();
          }}
          isOpen={!!applicationToEdit}
          application={applicationToEdit}
        />
        {isEventApplicationsFetching ||
        isLegacyEventsFetching ||
        isConferenceDaysEventsFetching ||
        isEventTicketHolderFetching ? (
          <LoadingDialog />
        ) : null}
        <section>
          {!isEventApplicationsFetching &&
            !isEventTicketHolderFetching &&
            !isLegacyEventsFetching &&
            !isConferenceDaysEventsFetching &&
            !eventApplications?.applications?.length &&
            !legacyEvents?.events?.length &&
            !eventTicketHolders?.eventTickets?.length &&
            !eventSectionEvents?.eventPayments?.length && (
              <span className="italic sans-serif text-gray-400">
                No events found
              </span>
            )}
          {eventApplications &&
          eventApplications.applications &&
          eventApplications.applications.length ? (
            <ul>
              {eventApplications.applications.map((a: EventApplication) => {
                return (
                  <li
                    key={a.id}
                    className="mb-4 flex justify-between border-b border-gray-200 pb-4"
                  >
                    <div>
                      <p className="font-bold text-gray-600 text-lg sans-serif mb-1">
                        {a.event.name}
                      </p>
                      <p className="sans-serif text-gray-500">
                        {formatDates(a.event.dates)}
                      </p>
                      <div className="mt-2">
                        <ApplicationStatus status={a.status} />
                      </div>
                      {![EventPaymentStatus.PENDING].some(
                        s => s === getPaymentStatus(a)
                      ) && (
                        <div className="mt-2">
                          <ApplicationPaymentStatus
                            status={getPaymentStatus(a)}
                          />
                        </div>
                      )}
                    </div>
                    <div className="my-auto">
                      {(a.status === 'STARTED' || a.status === 'SUBMITTED') && (
                        <Button
                          text={
                            a.status === 'STARTED'
                              ? 'Complete Application'
                              : 'Review Application'
                          }
                          size="sm"
                          onClick={() =>
                            a.status === 'SUBMITTED'
                              ? navigate(
                                  `/members/events/applications/${a.id}/manage/review-and-submit`
                                )
                              : navigate(
                                  `/members/events/applications/${a.id}/manage/general-information`
                                )
                          }
                        />
                      )}
                      {a.status === 'ACCEPTED' &&
                        [
                          EventPaymentStatus.PENDING,
                          EventPaymentStatus.AWAITING_PO
                        ].some(s => s === getPaymentStatus(a)) && (
                          <div className="flex">
                            <div className="mr-2">
                              <Button
                                text="Change payment method"
                                size="sm"
                                onClick={() => setApplicationToEdit(a)}
                              />
                            </div>
                            <div className="mr-2">
                              <Button
                                disabled={
                                  eventApplicationPurchaseIsLoading === a.id
                                }
                                isLoading={
                                  eventApplicationPurchaseIsLoading === a.id
                                }
                                text={getPaymentOption(a?.paymentOption?.label)}
                                size="sm"
                                onClick={() => proceedWithPayment(a.id)}
                              />
                            </div>
                          </div>
                        )}
                      {[
                        EventPaymentStatus.COMPLIMENTARY,
                        EventPaymentStatus.PAID,
                        EventPaymentStatus.AWAITING_INVOICE,
                        EventPaymentStatus.PO_SUBMITTED,
                        EventPaymentStatus.INVOICED,
                        EventPaymentStatus.PENDING,
                        EventPaymentStatus.AWAITING_PO
                      ].some(s => s === getPaymentStatus(a)) && (
                        <Button
                          text="Resources"
                          size="sm"
                          onClick={() =>
                            navigate(
                              `/members/events/${a.event.id}/resources?eaId=${a.id}`
                            )
                          }
                        />
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : null}
        </section>

        <section>
          {legacyEvents && legacyEvents.events && legacyEvents.events.length ? (
            <ul>
              {legacyEvents.events.map((application: any, i: number) => (
                <div
                  key={i}
                  className="mb-4 flex justify-between border-b border-gray-200 pb-2"
                >
                  <div className="w-1/2">
                    <p className="font-bold text-gray-600 text-lg sans-serif mb-1">
                      {application.Event.Name}
                    </p>
                    <p className="sans-serif text-gray-500">
                      {application.ApplicationStatus.Description}
                    </p>
                    <p className="sans-serif text-gray-500">
                      {application.PaymentStatus.Description}
                    </p>
                  </div>
                  <div>
                    {/* <div className="mb-3">
                      {application.PaymentTypeID != 2 &&
                        application.ApplicationStatusID == 4 &&
                        application.PaymentStatusID == 1 &&
                        application.Event.ApplicationFlag &&
                        paymentTypes && (
                          <div className="mt-2">
                            <label className="uppercase text-gray-500 text-sm sans-serif">
                              Change Payment Type
                            </label>
                            <ChangePaymentType
                              application={application}
                              paymentTypes={paymentTypes.filter(
                                t => t.PaymentTypeID !== 2
                              )}
                              onSubmit={paymentTypeId =>
                                mutation.mutate({
                                  eventApplicationId:
                                    application.EventApplicationID,
                                  paymentTypeId
                                })
                              }
                            />
                          </div>
                        )}
                    </div> */}
                    <div className="my-auto flex">
                      {application.ApplicationStatusID == 1 &&
                        application.Event.ApplicationFlag &&
                        application.Event.Published && (
                          <a
                            href={`${process.env.GATSBY_LEGACY_SITE_URL}/member/events/rules-and-regulations?eventID=${application.EventID}`}
                          >
                            <Button text="Complete Application" />
                          </a>
                        )}

                      {application.ApplicationStatusID == 4 &&
                        application.PaymentStatusID == 1 &&
                        (application.PaymentTypeID == 1 ||
                          application.PaymentTypeID == 4) && (
                          <Button
                            text="Make Payment by CC or Check"
                            onClick={() =>
                              makePayment(application.EventApplicationID)
                            }
                          />
                        )}

                      {application.ApplicationStatusID == 4 &&
                        application.PaymentStatusID == 1 &&
                        (application.PaymentTypeID == 3 ||
                          application.PaymentTypeID == 5) && (
                          <a
                            href={`${process.env.GATSBY_LEGACY_SITE_URL}/member/payment/creditcard?EventID=${application.EventID}`}
                          >
                            <Button text="Make Payment by PO" />
                          </a>
                        )}
                    </div>
                  </div>
                </div>
              ))}
            </ul>
          ) : null}
        </section>

        <section>
          {eventSectionEvents &&
            eventSectionEvents.eventPayments?.map((eventPayment: any) => {
              return (
                <li
                  key={eventPayment.id}
                  className="mb-4 flex justify-between border-b border-gray-200 pb-4"
                >
                  <div className="w-3/4">
                    <p className="font-bold text-gray-600 text-lg sans-serif mb-1">
                      {eventPayment.events[0]?.name}
                    </p>
                    <p className="sans-serif text-gray-500">
                      {formatDates(
                        eventPayment.events[0]?.dates || ([] as string[])
                      )}
                    </p>
                    <ApplicationPaymentStatus status={eventPayment.status} />
                  </div>
                  {[
                    EventPaymentStatus.PAID,
                    EventPaymentStatus.COMPLIMENTARY,
                    EventPaymentStatus.AWAITING_INVOICE,
                    EventPaymentStatus.INVOICED,
                    EventPaymentStatus.PENDING,
                    EventPaymentStatus.AWAITING_PO
                  ].some(s => s === eventPayment.status) ? (
                    <div className="my-auto w-1/4 text-right ml-8 flex flex-row-reverse gap-2">
                      {[
                        EventPaymentStatus.PAID,
                        EventPaymentStatus.INVOICED,
                        EventPaymentStatus.AWAITING_INVOICE,
                        EventPaymentStatus.COMPLIMENTARY
                      ].some(s => s === eventPayment.status) ? (
                        <Button
                          text="Resources"
                          size="sm"
                          onClick={() =>
                            navigate(
                              `/members/events/${
                                eventPayment.events[0]?.id
                              }/resources${
                                eventPayment.events[0]?.sectionId
                                  ? `?eventSectionId=${eventPayment.events[0]?.sectionId}`
                                  : ''
                              }`
                            )
                          }
                        />
                      ) : null}

                      {[
                        EventPaymentStatus.PAID,
                        EventPaymentStatus.AWAITING_INVOICE,
                        EventPaymentStatus.INVOICED
                      ].some(s => s === eventPayment.status) ? (
                        <Button
                          text="Assign Tickets"
                          size="sm"
                          onClick={() =>
                            navigate(
                              `/members/event/assign-tickets?eventPaymentId=${eventPayment.id}`
                            )
                          }
                        />
                      ) : null}
                      {eventPayment.status === EventPaymentStatus.PENDING ? (
                        <Button
                          text="Cancel Request"
                          size="sm"
                          onClick={() => {
                            if (
                              confirm(
                                'This ticket request will be cancelled. Are you sure you want to proceed?'
                              )
                            ) {
                              handleCancelEventPayment(eventPayment.id);
                            }
                          }}
                          color="error"
                        />
                      ) : eventPayment.status ===
                        EventPaymentStatus.AWAITING_PO ? (
                        <Button
                          text="Submit Purchase Order"
                          size="sm"
                          onClick={() => {
                            navigate(
                              `/members/event-cart/checkout/purchase-order?eventPaymentId=${eventPayment.id}`
                            );
                          }}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </li>
              );
            })}
        </section>

        <section>
          {eventTicketHolders &&
            eventTicketHolders.eventTickets?.map((eventTicket: any) => {
              const eventSection =
                eventTicket?.eventTicketRequestEventSection?.eventSection;
              return (
                <li
                  key={eventTicket.id}
                  className="mb-4 flex justify-between border-b border-gray-200 pb-4"
                >
                  <div className="w-3/4">
                    <p className="font-bold text-gray-600 text-lg sans-serif mb-1">
                      {eventSection?.group?.eventPath?.event?.name}
                    </p>
                    <p className="sans-serif text-gray-500">
                      {formatDates(eventSection?.dates || ([] as string[]))}
                    </p>
                  </div>

                  <div className="my-auto w-1/4 text-right ml-8 flex flex-row-reverse gap-2">
                    <Button
                      text="Resources"
                      size="sm"
                      onClick={() =>
                        navigate(
                          `/members/events/${eventSection?.group?.eventPath?.event?.id}/resources?eventSectionId=${eventSection?.id}`
                        )
                      }
                    />
                  </div>
                </li>
              );
            })}
        </section>
      </Card>
    </>
  );
};

export default EventApplications;
