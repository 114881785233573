import React, { useContext, useEffect, useState } from 'react';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import classnames from 'classnames';
import {
  flatPolyfill,
  formatDateWithoutTimezone,
  isEventDeadlineOver
} from '../../util';
import ManageRosterLink from './ManageRosterLink';
import { OrganizationPackage } from './OrganizationPackageTypes';
import { ProductPanelTitle } from './ProductPanel';
import SelectionInstructions from './SelectionInstructions';
import UserSelect from './UserSelect';
import { useQuery } from 'react-query';
import { getEventApplications } from './Queries';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import NoSelectionsWarning from './NoSelectionsWarning';
import {
  InstituteTicketAssignment,
  TicketAssignments,
  UserOptionType
} from './AssignTickets';
import { PackageContext } from '../../context/PackageContext';
import { User } from './NewApiTypes.generated';
import {
  getTicketAllocationNotAvailableMessage,
  isNotAvailableForPurchase
} from '../../helpers/eventSections.helper';
import UnableToEditNotice from './UnableToEditNotice';
import { getUserInfo } from '../../util/auth';

function getApplication(applications: any, eventId: number, userId: number) {
  var application = null;

  if (eventId && userId && applications) {
    for (var aIndex = 0; aIndex < applications.length; aIndex++) {
      var itrApplication = applications[aIndex];

      if (
        itrApplication &&
        itrApplication.EventID == eventId &&
        itrApplication.UserID == userId
      ) {
        application = itrApplication;
      }
    }
  }

  return application;
}

function getApplicationStatus(userMatch: UserOptionType & { status?: string }) {
  if (!userMatch?.status) return '--';

  if (userMatch.status === 'STARTED') return 'Started';
  if (userMatch.status === 'SUBMITTED') return 'Submitted';
  if (userMatch.status === 'ACCEPTED') return 'Accepted';
  if (userMatch.status === 'REJECTED') return 'Rejected';
  if (userMatch.status === 'CHANGES_REQUESTED') return 'Changes Requested';

  return '--';
}

function getApplicationUrl(eventId: number, userId: number) {
  var applicationUrl = '#';

  if (eventId && userId) {
    applicationUrl = `${process.env.GATSBY_LEGACY_SITE_URL}/member/events/event-application?eventID=${eventId}&userID=${userId}&acceptedRules=true`;
  }

  return applicationUrl;
}

function allSpotsAssigned(
  assignments: InstituteTicketAssignment,
  count: number
) {
  return assignments?.assignedUsers.filter(Boolean).length === count;
}

interface InstituteApplicantPanelProps {
  open: boolean;
  title: string;
  assignmentsCount: number;
  users: UserOptionType[];
  dates: string[];
  ticketAssignments: InstituteTicketAssignment;
  blockApplicantAllocationMessage?: string;
  onAssignmentSelect: (a: { userId: string; position: number }) => void;
  onClearAssignment: (a: { userId: string; position: number }) => void;
}

const InstituteApplicantPanel = (props: InstituteApplicantPanelProps) => {
  const buttonClasses = classnames({
    'flex justify-between w-full px-4 py-2 font-medium text-left text-blue-100 bg-primary rounded-t-lg hover:bg-primary-dark focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75 sans-serif': true,
    'rounded-b-lg': !props.open
  });
  const chevronClasses = classnames({
    'w-5 h-5 text-blue-100': true,
    'transform rotate-180': props.open
  });

  return (
    <>
      <Disclosure.Button className={buttonClasses}>
        <span className="sans-serif text-lg">
          <FontAwesomeIcon
            icon={
              allSpotsAssigned(props.ticketAssignments, props.assignmentsCount)
                ? faCheckCircle
                : faCircle
            }
            className="text-blue-100 mr-2"
          />
          {props.title}
        </span>
        <ChevronUpIcon className={chevronClasses} />
      </Disclosure.Button>
      <Disclosure.Panel className="p-6 text-gray-700 sans-serif border-gray-300 border-b border-l border-r rounded-b-lg">
        {props.blockApplicantAllocationMessage && (
          <div className="w-1/2 m-auto mt-2 mb-6">
            <UnableToEditNotice text={props.blockApplicantAllocationMessage} />
          </div>
        )}
        <div className="w-full flex mb-4">
          <div className="mr-10">
            <ProductPanelTitle title="Number of Seats" />
            <p className="sans-serif">{props.assignmentsCount}</p>
          </div>
        </div>
        <div className="w-full mb-2">
          <div className="mb-2">
            <ProductPanelTitle title="Ticket Assignments" />
          </div>
          <table className="min-w-full divide-y divide-gray-200 p-2">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="text-left p-2 sans-serif">
                  Dates
                </th>
                <th scope="col" className="text-left p-2 sans-serif">
                  Application Status
                </th>
                {/* <th scope="col" className="text-left p-2 sans-serif">
                  Grade
                </th> */}
                <th scope="col" className="text-left p-2 sans-serif">
                  User
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Array(props.assignmentsCount)
                .fill(null)
                .map((_n, i: number) => {
                  const userMatch =
                    props.ticketAssignments.assignedUsers[i] || null;
                  return (
                    <tr key={i}>
                      <td className="p-2 sans-serif">
                        {
                          <>
                            {props.dates.map((d, index) => (
                              <p key={index}>
                                {formatDateWithoutTimezone(new Date(d), true)}
                              </p>
                            ))}
                          </>
                        }
                      </td>
                      <td className="p-2 sans-serif">
                        {getApplicationStatus(userMatch || null)}
                      </td>
                      <td className="p-2 sans-serif w-1/4">
                        <UserSelect
                          isDisabled={!!props.blockApplicantAllocationMessage}
                          options={
                            props.users.filter(
                              u =>
                                !props.ticketAssignments.assignedUsers.some(
                                  au => au && au.value === u.value
                                )
                            ) || []
                          }
                          // @ts-ignore
                          value={userMatch || null}
                          onChange={(value: any) =>
                            props.onAssignmentSelect({
                              userId: value.value,
                              position: i
                            })
                          }
                        />
                        {userMatch && (
                          <div className="flex justify-end">
                            <button
                              disabled={!!props.blockApplicantAllocationMessage}
                              className="mt-1 text-sm sans-serif text-red-600 text-right"
                              onClick={() =>
                                props.onClearAssignment({
                                  userId: userMatch.value,
                                  position: i
                                })
                              }
                            >
                              Clear selection
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </Disclosure.Panel>
    </>
  );
};
interface AssignApplicantsProps {
  ticketAssignments: TicketAssignments;
  packageRequestOrganizationUsers: (User & { isPending?: boolean })[];
  setHasUnsavedChanges: any;
  tickets: InstituteTicketAssignment[];
  onUpdateOrganizationPackage: (a: InstituteTicketAssignment) => void;
}
const AssignApplicants = (props: AssignApplicantsProps) => {
  const { ticketAssignments, tickets } = props;
  const packageContext = useContext(PackageContext);
  const [user, setUser] = useState();
  useEffect(() => {
    const fetchUser = async () => {
      setUser(await getUserInfo());
    };
    fetchUser();
  }, []);

  return (
    <>
      <h3 className="text-2xl text-gray-600 font-bold my-4">
        Assign Institute Applicants
      </h3>
      <>
        <div className="my-2">
          <ManageRosterLink
            organizationId={String(packageContext.organizationId)}
            withArrow
          />
        </div>
        {!ticketAssignments?.institutes?.length ? (
          <div className="w-1/2 m-auto my-6">
            <NoSelectionsWarning />
          </div>
        ) : (
          <>
            <p className="mb-4 sans-serif">
              Please select associated users to assign towards tickets
            </p>
            <div className="w-1/2 m-auto my-6">
              <SelectionInstructions />
            </div>
            {ticketAssignments.institutes.map((ev, i: number) => {
              return (
                <div className="mb-2" key={i}>
                  <Disclosure>
                    {({ open }) => {
                      const packageRequestInstitute =
                        ev.packageInstitutes?.[0]
                          ?.packageRequestInstitutes?.[0];

                      const assignmentsCount =
                        (packageRequestInstitute?.overrideDate
                          ? packageRequestInstitute.overrideAmount
                          : packageRequestInstitute?.requestedParticipants) ||
                        0;

                      const existingTickets: InstituteTicketAssignment = tickets.find(
                        e => e.instituteId === ev.id
                      ) || {
                        instituteId: ev.id,
                        assignedUsers: Array(assignmentsCount).fill(null)
                      };

                      const usersOptions =
                        props.packageRequestOrganizationUsers?.map(sd => ({
                          value: sd?.id || '',
                          label:
                            [
                              sd?.firstName,
                              sd?.lastName,
                              sd?.isPending && '(Pending)'
                            ]
                              .filter(Boolean)
                              .join(' ') || '',
                          email: sd?.email || ''
                        })) || [];

                      const spotsLack =
                        assignmentsCount - existingTickets.assignedUsers.length;

                      if (spotsLack > 0) {
                        existingTickets.assignedUsers.concat(
                          Array(spotsLack).fill(null)
                        );
                      }

                      return (
                        <InstituteApplicantPanel
                          open={open}
                          title={ev.name || ''}
                          assignmentsCount={assignmentsCount}
                          blockApplicantAllocationMessage={
                            isNotAvailableForPurchase({
                              event: ev,
                              type: 'EVENT',
                              isImpersonated: (user as any)?.impersonator
                            })
                              ? getTicketAllocationNotAvailableMessage(
                                  ev.daysBeforePurchaseClose
                                )
                              : isEventDeadlineOver(ev)
                              ? 'Ticket allocation is not available because the event deadline has passed.'
                              : undefined
                          }
                          ticketAssignments={existingTickets}
                          users={usersOptions}
                          dates={
                            ev.isRangeDate
                              ? ([ev.startDate, ev.endDate] as string[])
                              : (ev.dates as string[])
                          }
                          onAssignmentSelect={userSelect => {
                            const assignedUsers = [
                              ...existingTickets.assignedUsers
                            ];

                            assignedUsers[
                              userSelect.position
                            ] = (usersOptions.find(
                              u => u.value === userSelect.userId
                            ) || null) as any;

                            const updatedOrgPackageEvent: InstituteTicketAssignment = {
                              ...existingTickets,
                              assignedUsers
                            };

                            props.onUpdateOrganizationPackage(
                              updatedOrgPackageEvent
                            );
                            props.setHasUnsavedChanges(true);
                          }}
                          onClearAssignment={e => {
                            const assignedUsers: (UserOptionType | null)[] = [
                              ...existingTickets.assignedUsers
                            ];

                            if (assignedUsers[e.position]?.value === e.userId) {
                              assignedUsers[e.position] = null;
                            }

                            const updatedOrgPackageEvent: InstituteTicketAssignment = {
                              ...existingTickets,
                              assignedUsers: assignedUsers as any[]
                            };

                            props.onUpdateOrganizationPackage(
                              updatedOrgPackageEvent
                            );
                            props.setHasUnsavedChanges(true);
                          }}
                        />
                      );
                    }}
                  </Disclosure>
                </div>
              );
            })}
          </>
        )}
      </>
    </>
  );
};

export default AssignApplicants;
